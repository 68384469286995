/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Link } from 'react-router-dom';

import OfferCardComponent from './OfferCardComponent';
import FormationCardComponent from '../../Formations/FormationCardComponent';

import './styles.scss';
import SpinnerLoader from '../../Utils/UI/SpinnerLoader';

const HomeOffer = ({ eventsList, isLoading }) => {
  // eslint-disable-next-line no-unused-vars
  const [categories, setCategories] = useState([
    {
      title: 'Je suis une entreprise',
      slug: 'entreprises',
    },
    {
      title: 'Je suis un formateur',
      slug: 'formateurs',
    },
    {
      title: 'Je suis un coach',
      slug: 'coachs',
    },
  ]);

  const [numbers] = useState([
    {
      title: 'Expert depuis 2005',
      slug: 'expert',
    },
    {
      title: '+ de 92% de taux de safisfaction*',
      slug: 'satisfaction',
    },
    {
      title: 'Outils innovants',
      slug: 'outils',
    },
  ]);

  return (
    <div className="offers">
      {/* CONTENT INFO */}
      <section>
        <p className="offers-pres text-header">
          Vous rencontrez des difficultés à motiver vos équipes&#8239;? Vos managers manquent de temps et d’outils pour
          fédérer leurs équipes&#8239;? Vos collaborateurs manquent de performance&#8239;? Vous avez besoin d’aide dans
          la transformation de votre entreprise&#8239;?
        </p>
        <h2 className="offers-title title-1">
          Forma’coach :<br /> votre solution
        </h2>
        <p className="offers-pres text-header">
          Forma’Coach vous propose une vaste sélection de formations, d’ateliers et de ressources. Nos formations sont
          dispensées par une équipe de coachs formateurs expérimentés. Autant de talents pour vous accompagner dans vos
          ambitions de transformation. Formateurs, coachs, formez-vous aux outils innovants.
        </p>

        {/* ENTREPRISE / FORMATEUR / COACH CARDS */}
        <div className="offers-categories">
          {categories?.map((offer) => (
            <Link key={offer.slug} to={`/${offer.slug}`}>
              <OfferCardComponent offer={offer} hasBorder={true} />
            </Link>
          ))}
        </div>
      </section>

      {/* FORMATIONS */}
      <section>
        <h3 className="offers-list--title title-3 italic">Les prochaines formations :</h3>
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <div className="offers-formations">
            {/* Map the first 2 events */}
            {eventsList
              // ?.filter((event) => dayjs(event.dateEnd) > dayjs())
              .slice(0, 2)
              .map((event, index) => {
                // eslint-disable-next-line no-param-reassign
                index += 1;
                return <FormationCardComponent key={event._id} event={event} index={index} />;
              })}
          </div>
        )}
      </section>

      {/* CHIFFRES */}
      <section className="offers-numbers--item">
        <div className="offers-numbers">
          {numbers?.map((offer) => (
            <OfferCardComponent key={offer.slug} offer={offer} />
          ))}
        </div>
        <div className="offers-numbers--legend light">
          * Indicateurs de résultat de l’ensemble des formations réalisées au premier semestre 2022 : Taux de
          satisfaction des stagiaires : + de 92% Taux de recommandation des stagiaires : + de 91%. Analyse des réponses
          aux questionnaires de satisfaction à chaud réalisés à la fin de chaque formation en présentiel ou en
          distanciel. Nos modalités d’évaluation : Prédictive - Sommative - À chaud. En fonction des donneurs d’ordre
          des questionnaires de satisfaction à froid peuvent être réalisés.
        </div>
      </section>
    </div>
  );
};

HomeOffer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string.isRequired,
      dateEnd: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),
};

export default HomeOffer;
