import { useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import { MdPhone, MdMailOutline, MdOutlineLocationOn } from 'react-icons/md';
import SpinnerLoader from '../Utils/UI/SpinnerLoader';

import './styles.scss';
import logo from '../HeaderComponent/logo-small.png';
import qualiopi from './qualiopi.JPG';
import certificat from './certificat.pdf';

const Footer = () => {
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <div className="footer">
      {/* CONTENT */}
      <div className="footer-content">
        {/* LOGO + CONTACTS */}
        <div className="footer-content--left">
          <div className="footer-content--left_logo">
            {!imgLoaded && <SpinnerLoader />}
            <img src={logo} alt="formacoach logo" onLoad={() => setImgLoaded(true)} />
          </div>
          <ul className="footer-content--left_contacts">
            <li className="footer-content--left_contacts-item">
              <MdOutlineLocationOn />
              26, rue de l'étoile, 75017 Paris
            </li>
            <li className="footer-content--left_contacts-item">
              <MdPhone />
              +336 03 59 83 46 / +337 68 36 72 02
            </li>
            <li className="footer-content--left_contacts-item">
              <MdMailOutline />
              contact@formacoachdigital.com
            </li>
          </ul>
        </div>
       <div className="footer-qualiopi">
           <a href={certificat} target="_blank" rel="noopener noreferrer">
          <img src={qualiopi} alt="qualiopi" />
          </a>
        </div>
        <div className="footer-content--right">
          <Link to={'/mentions-legales'}>Mentions légales</Link>
          <span>Copyright Forma'Coach {dayjs().get('year')}</span>
        </div>
      </div>
      {/* WAVE BACKGROUND */}
    </div>
  );
};

export default Footer;
