import { SET_CURRENT_LOCATION, SET_PREVIOUS_LOCATION } from '../actions/navigation';

export const initialState = {
  currentLocation: '',
  previousLocation: '',
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload.pathname,
      };
    case SET_PREVIOUS_LOCATION:
      return {
        ...state,
        previousLocation: action.payload.pathname,
      };
    default:
      return state;
  }
};

export default reducer;
