import './styles.scss';

const HomePresentation = () => {
  // eslint-disable-next-line max-len
  const mask = `M ${window.innerWidth} 778.072 c -732.789 -86.975 -1499 85.976 -${window.innerWidth} -5.998 c 0 -29.991 0 -752.703 0 -752.703 c 420 91.974 1186.21 -80.977 ${window.innerWidth} 5.998 V 778.072 Z`;

  return (
    <div className="presentation">
      <div className="presentation-content">
        <h2 className="presentation-content--title title-1">Faites de vos managers des leaders</h2>
        <ul className="presentation-content--list">
          <li className="presentation-content--list_item">
            <span className="title-3 regular italic">Favoriser l’équité homme / femme</span>
          </li>
          <li className="presentation-content--list_item">
            <span className="title-3 regular italic">Augmenter la performance de vos équipes</span>
          </li>
          <li className="presentation-content--list_item">
            <span className="title-3 regular italic">Diminuer le turn-over et l’absentéisme</span>
          </li>
          <li className="presentation-content--list_item">
            <span className="title-3 regular italic">Développer votre intelligence conflictuelle</span>
          </li>
        </ul>
      </div>
      <svg viewBox={`0 0 ${window.innerWidth} 400`} preserveAspectRatio="none">
        <defs>
          <clipPath id="presentation-img-clip">
            <path fill="#FFFFFF00" className="header-wave--path" d={mask} />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default HomePresentation;
