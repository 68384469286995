import './styles.scss';

const ValueMobileComponent = () => (
  <div className="values-mobile">
    <h2 className="values-mobile--title black">
      <span className="values-mobile--title_first">É</span>quité
    </h2>
    <h2 className="values-mobile--title black">
      <span className="values-mobile--title_first">T</span>enségrité
    </h2>
    <h2 className="values-mobile--title black">
      <span className="values-mobile--title_first">H</span>umanisme
    </h2>
    <h2 className="values-mobile--title black">
      <span className="values-mobile--title_first">I</span>ntelligence
    </h2>
    <h2 className="values-mobile--title black">
      <span className="values-mobile--title_first">Q</span>ualité
    </h2>
    <h2 className="values-mobile--title black">
      <span className="values-mobile--title_first">U</span>nion
    </h2>
    <h2 className="values-mobile--title black">
      <span className="values-mobile--title_first">E</span>xcellence
    </h2>
  </div>
);

export default ValueMobileComponent;
