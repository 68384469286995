import { connect } from 'react-redux';
import AtelierDetailsPage from '../pages/AtelierDetailsPage';

const mapStateToProps = (state) => ({
  ateliersList: state.events.ateliersList,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AtelierDetailsPage);
