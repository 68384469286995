export const GET_EVENTS_LIST = 'GET_EVENTS_LIST';
export const SET_EVENTS_LIST = 'SET_EVENTS_LIST';
export const GET_ATELIERS_LIST = 'GET_ATELIERS_LIST';
export const SET_ATELIERS_LIST = 'SET_ATELIERS_LIST';

export const setAteliersList = (payload) => ({
  type: SET_ATELIERS_LIST,
  payload,
});

export const getAteliersList = () => ({
  type: GET_ATELIERS_LIST,
});

export const setEventsList = (payload) => ({
  type: SET_EVENTS_LIST,
  payload,
});

export const getEventsList = () => ({
  type: GET_EVENTS_LIST,
});
