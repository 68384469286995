/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Handle Time
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr';

import { MdTimer } from 'react-icons/md';

import SpinnerLoader from '../../Utils/UI/SpinnerLoader';
import './styles.scss';

dayjs.extend(relativeTime);
dayjs.locale('fr');

const FormationListItemComponent = ({ event, index }) => {
  const [pictoLoaded, setPictoLoaded] = useState(false);

  return (
    <Link to={`/events/${event._id}/${index > 2 ? 2 : index}`} className="formation-listItem">
      <div className="formation-listItem--title">{event.title}</div>
      <div className="formation-listItem--details">
        <MdTimer />
        <div className="formation-listItem--details_duration">
          {event.duration ? '2 heures | Atelier' : `${dayjs(event.dateEnd).add(1, 'day').to(event.dateStart, true)}`}
        </div>
        {event.certification && (
          <div className="formation-listItem--details_certification">
            |{' '}
            <div>
              {!pictoLoaded && <SpinnerLoader />}
              <img
                className="formation-listItem--details_certification-picto"
                src={'/icons/certification-icon.svg'}
                alt={'Formation certifiante'}
                onLoad={() => setPictoLoaded(true)}
              />
            </div>
            Certifiante
          </div>
        )}
      </div>
    </Link>
  );
};

FormationListItemComponent.propTypes = {
  index: PropTypes.number,
  event: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    program: PropTypes.string.isRequired,
    category: PropTypes.shape({
      catTitle: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
    subcategory: PropTypes.shape({
      subCatTitle: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
    audience: PropTypes.string.isRequired,
    prerequisites: PropTypes.string.isRequired,
    objectives: PropTypes.string.isRequired,
    modalities: PropTypes.string.isRequired,
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    duration: PropTypes.string,
    location: PropTypes.string.isRequired,
    price: PropTypes.number,
    intra: PropTypes.bool.isRequired,
    inter: PropTypes.bool.isRequired,
    certification: PropTypes.bool.isRequired,
    display: PropTypes.bool.isRequired,
  }),
};

FormationListItemComponent.defaultProps = {
  dateStart: '',
  index: 2,
  dateEnd: '',
  duration: null,
};

export default FormationListItemComponent;
