import './styles.scss';

import logos from './clients.json';

const ClientsLogosComponent = () => {
  const value1 = 'M0,0c227,0,1717,0,1920,0c0,45,0,120,0,120C1186.579,33,421,206,0,114C0,84,0,0,0,0z';

  return (
    <>
      <div className="background">
        <svg
          className="background--wave"
          xmlns="http://www.w3.org/2000/svg"
          // If screen smaller than 1281 we pull up the wave
          viewBox={`${window.innerWidth < 1281 ? '0 -60 1920 210' : '0 0 1920 150'}`}
          preserveAspectRatio="none"
          x="0"
          y={`-${window.innerHeight}`}
        >
          <path fill="#FFFFFF" className="clientsLogos-wave--path" d={`${value1}`} />
        </svg>
        {/* {window.innerWidth < 1281 && } */}
      </div>
      <div className="clientsLogos">
        <div className="clientsLogos-rectangle" />
        <h3 className="clientsLogos-title title-3">Ils nous font confiance</h3>
        {/* Regular logos */}
        <ul className="clientsLogos-list--big">
          {logos.slice(3).map((logo) => (
            <li key={logo.name} className="clientsLogos-list--big_item">
              <img src={`/logos/${logo.url}`} alt={`logos ${logo.name}`} title={logo.name} />
            </li>
          ))}
        </ul>
        {/* Small logos */}
        <ul className="clientsLogos-list--small">
          {logos.slice(0, 3).map((logo) => (
            <li key={logo.name} className="clientsLogos-list--small_item">
              <img src={`/logos/${logo.url}`} alt={`logos ${logo.name}`} title={logo.name} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ClientsLogosComponent;
