export const SUBMIT_CONTACT_FORM = 'SUBMIT_CONTACT_FORM';
export const SUBMIT_INSCRIPTION_FORM = 'SUBMIT_INSCRIPTION_FORM';

export const submitContactForm = (payload) => ({
  type: SUBMIT_CONTACT_FORM,
  payload,
});

export const submitInscriptionForm = (payload) => ({
  type: SUBMIT_INSCRIPTION_FORM,
  payload,
});
