import React from 'react';

const CoachingElements = () => (
  <div className="entreprise-list gsap">
    <h3 className="entreprise-list--title title-3">Exemples de champs d'expertise :</h3>
    <ul>
      <li className="entreprise-list--item text-main">- Manager débutant</li>
      <li className="entreprise-list--item text-main">- Prendre en main une nouvelle équipe</li>
      <li className="entreprise-list--item text-main">- Se positionner avec assertivité</li>
      <li className="entreprise-list--item text-main">- Gestion des conflits (avec test psychométrique) </li>
      <li className="entreprise-list--item text-main">- Réussir sa reconversion, sa mobilité interne</li>
      <li className="entreprise-list--item text-main">- Développer sa résilience</li>
      <li className="entreprise-list--item text-main">- Manager résilient face à des situations complexes</li>
      <li className="entreprise-list--item text-main">
        - Leadership et résilience au féminin (avec test psychométrique)
      </li>
    </ul>
  </div>
);

export default CoachingElements;
