import { connect } from 'react-redux';
import menuComponent from '../components/MenuComponent';

import { openMenu, closeMenu } from '../actions/menu';
import { setPreviousLocation } from '../actions/navigation';

const mapStateToProps = (state) => ({
  isMenuOpen: state.menu.isMenuOpen,
});

const mapDispatchToProps = (dispatch) => ({
  openMenu: () => dispatch(openMenu()),
  closeMenu: () => dispatch(closeMenu()),
  setPreviousLocation: (payload) => dispatch(setPreviousLocation(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(menuComponent);
