/* eslint-disable operator-linebreak */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
// prettier-ignore
import {
  useMemo,
  useEffect,
  useRef,
  useState
} from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import PropTypes from 'prop-types';
import './styles.scss';

import SpinnerLoader from '../../components/Utils/UI/SpinnerLoader';
import NavButton from '../../components/Utils/UI/NavButton';
import PagesFormationsComponent from '../../components/PagesFormationsComponent';

const CoachingPage = ({ isLoading, eventsList, categories }) => {
  const [category, setCategory] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setCategory(categories?.find((cat) => cat.catTitle === 'coaching'));
    }

    return () => {
      isMounted = false;
    };
  }, [categories]);

  const handleFilterEvents = (subcat) => {
    if (
      subcat._id === selectedCategoryId &&
      selectedCategoryId !== null
    ) {
      setSelectedCategoryId(null);
      setSelectedCategoryName(null);
      // setIsSelected(false);
    }
    // prettier-ignore
    else {
      setSelectedCategoryId(subcat._id);
      setSelectedCategoryName(subcat.subCatTitle);
      // setIsSelected(true);
    }
  };

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const scrollTl = useRef();

  const scrollItem = (item) => {
    // TIMELINE
    scrollTl.current.fromTo(
      item,
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        duration: 10,
        opacity: 1,
        ease: 'expo',
        scrollTrigger: {
          scrub: 2,
          trigger: item,
          toggleActions: 'play reverse play reverse',
          start: 'top 90%',
          end: 'top 75%',
        },
      },
      '+=1'
    );

    scrollTl.current.play();

    return scrollTl;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // Scroll timeline
      scrollTl.current = gsap.timeline({
        paused: true,
      });

      // Get every line and put it in an array
      gsap.utils.toArray('.gsap').forEach((line) => {
        scrollItem(line);
      });
    }

    return () => {
      isMounted = false;
      scrollTl.current.kill();
    };
  }, []);

  return (
    <div className="coaching page">
      <h2 className="title-1 gsap">Nos offres coachs</h2>
      <p
        className="text-header gsap"
        style={{
          marginBottom: '2rem',
        }}
      >
        Ennayla Arsafi, forme et coache des consultants formateurs depuis 2007. Elle est experte de l’ingénierie
        pédagogique et de formation. En tant que Master trainer d’outils innovants, elle vous propose des modules de
        formations de coachs certifiants, afin d’élargir votre palette d’outils de coaching. Forma’Coach est expert dans
        le domaine de la formation, son ambition est d’accompagner la professionnalisation des coachs.
      </p>
      {/* FORMATEURS & COACHS */}
      <section className="gsap">
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <PagesFormationsComponent
            page={'coachs'}
            eventsList={eventsList}
            selectedCategoryId={selectedCategoryId}
            selectedCategoryName={selectedCategoryName}
          />
          // <DisplayFormationsComponent filteredEvents={filteredEvents} eventsList={eventsList} category={'coachs'} />
        )}
        <div className="button">
          <NavButton to={'/contact'} text={'En savoir plus'} />
        </div>
      </section>
    </div>
  );
};

CoachingPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string.isRequired,
      dateEnd: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),
};

export default CoachingPage;
