/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
// eslint-disable no-multiple-empty-lines
import { createStore, applyMiddleware, compose } from 'redux';

// Import middlewares
import eventsMiddleware from '../middlewares/eventsMiddleware';
import contactsMiddleware from '../middlewares/contactsMiddleware';
import categoriesMiddleware from '../middlewares/categoriesMiddleware';

// Import reducers
import reducer from '../reducers';

// prettier-ignore
// const composeEnhancers = process.env.NODE_ENV === 'development' ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
const composeEnhancers = process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const enhancers = composeEnhancers(applyMiddleware(eventsMiddleware, contactsMiddleware, categoriesMiddleware));

const store = createStore(reducer, enhancers);

export default store;
