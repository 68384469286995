import PropTypes from 'prop-types';
import { BsEmojiSmileUpsideDown } from 'react-icons/bs';

const defaultMessage = "Il 'y a rien ici";

const EmptyComponent = ({ message = defaultMessage }) => (
  <div
    className="empty"
    style={{
      textAlign: 'center',
      margin: '5rem 0',
      color: 'white',
    }}
  >
    <BsEmojiSmileUpsideDown
      style={{
        fontSize: '3rem',
      }}
    />
    <h3>{message}</h3>
  </div>
);

EmptyComponent.propTypes = {
  message: PropTypes.string.isRequired,
};

export default EmptyComponent;
