import { connect } from 'react-redux';
import Formacoach from '../components/Formacoach';

import { startLoading, stopLoading, startFirstLoading } from '../actions/loading';
import { setCurrentLocation } from '../actions/navigation';
import { getEventsList, getAteliersList } from '../actions/events';
import { getCategories, getSubcategories } from '../actions/categories';

const mapStateToProps = (state) => ({
  isFirstLoading: state.loading.isFirstLoading,
  isLoading: state.loading.isLoading,
  eventsList: state.events.eventsList,
  loadingCpt: state.loading.loadingCpt,
});

const mapDispatchToProps = (dispatch) => ({
  startLoading: () => dispatch(startLoading()),
  startFirstLoading: () => dispatch(startFirstLoading()),
  stopLoading: () => dispatch(stopLoading()),
  setCurrentLocation: (payload) => dispatch(setCurrentLocation(payload)),
  getEventsList: () => dispatch(getEventsList()),
  getAteliersList: () => dispatch(getAteliersList()),
  getCategories: () => dispatch(getCategories()),
  getSubcategories: () => dispatch(getSubcategories()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Formacoach);
