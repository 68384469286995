export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SET_PREVIOUS_LOCATION = 'SET_PREVIOUS_LOCATION';

export const setPreviousLocation = (payload) => ({
  type: SET_PREVIOUS_LOCATION,
  payload,
});

export const setCurrentLocation = (payload) => ({
  type: SET_CURRENT_LOCATION,
  payload,
});
