import { connect } from 'react-redux';
import InscriptionPage from '../pages/InscriptionPage';

import { submitInscriptionForm } from '../actions/contacts';
import { resetResponse } from '../actions/apiResponses';

const mapStateToProps = (state) => ({
  eventsList: [...state.events.eventsList, ...state.events.ateliersList],
  isLoading: state.loading.isLoading,
  apiResponse: state.api.apiResponse,
  apiError: state.api.apiError,
  apiHasReturned: state.api.apiHasReturned,
});

const mapDispatchToProps = (dispatch) => ({
  submitInscriptionForm: (payload) => dispatch(submitInscriptionForm(payload)),
  resetResponse: () => dispatch(resetResponse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InscriptionPage);
