/* eslint-disable operator-linebreak */
/* eslint-disable no-underscore-dangle */
// prettier-ignore
import {
  useEffect,
  useState,
  useRef
} from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import PropTypes from 'prop-types';

import PagesFormationsComponent from '../../components/PagesFormationsComponent';
import SpinnerLoader from '../../components/Utils/UI/SpinnerLoader';
import NavButton from '../../components/Utils/UI/NavButton';

import './styles.scss';

const FormateurPage = ({ eventsList, isLoading, categories }) => {
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  // const [setIsSelected] = useState(false);
  const [category, setCategory] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);

  // GSAP
  const scrollTl = useRef();

  const scrollItem = (item) => {
    // TIMELINE
    scrollTl.current.fromTo(
      item,
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        duration: 10,
        opacity: 1,
        ease: 'expo',
        scrollTrigger: {
          scrub: 2,
          trigger: item,
          toggleActions: 'play reverse play reverse',
          start: 'top 95%',
          end: 'top 75%',
        },
      },
      '+=1'
    );

    scrollTl.current.play();

    return scrollTl;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // Scroll timeline
      scrollTl.current = gsap.timeline({
        paused: true,
      });

      // Get every line and put it in an array
      gsap.utils.toArray('.gsap').forEach((line) => {
        scrollItem(line);
      });
    }

    return () => {
      isMounted = false;
      scrollTl.current.kill();
    };
  }, []);

  // COMPONENT
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setCategory(categories?.find((cat) => cat.catTitle === 'formateurs'));
    }

    return () => {
      isMounted = false;
    };
  }, [categories]);

  const handleFilterEvents = (subcat) => {
    if (
      subcat._id === selectedCategoryId &&
      selectedCategoryId !== null
    ) {
      setSelectedCategoryId(null);
      setSelectedCategoryName(null);
      // setIsSelected(false);
    }
    // prettier-ignore
    else {
      setSelectedCategoryId(subcat._id);
      setSelectedCategoryName(subcat.subCatTitle);
      // setIsSelected(true);
    }
  };

  return (
    <div className="formateurs page">
      <h2 className="title-1 gsap">Nos offres formateurs</h2>
      <p
        className="text-header gsap"
        style={{
          marginBottom: '2rem',
        }}
      >
        Ennayla Arsafi, forme et coache des consultants formateurs depuis 2007. Elle est experte de l’ingénierie
        pédagogique et de formation. En tant que Master trainer d’outils innovants, elle vous propose des modules de
        formations de formateurs certifiants, afin d’élargir vos champs d’expertise et d’intervention… Forma’Coach est
        expert dans le domaine de la formation, son ambition est d’accompagner la professionnalisation des formateurs
        vers l’exigence qualité Qualiopi.
      </p>
      <div className="formateurs-categories gsap">
        <h3 className="formateurs-categories--title title-4 italic">Catégories :</h3>
        <div className="formateurs-categories--list">
          {category?.subcategories?.length > 0 &&
            category?.subcategories?.map((subcat) => (
              <div
                key={subcat._id}
                onClick={() => handleFilterEvents(subcat)}
                className={'formateurs-categories--list_item'}
                style={{
                  background: `${subcat._id === selectedCategoryId ? '#951b81' : '#FFFFFF50'}`,
                }}
              >
                {subcat.subCatTitle}
              </div>
            ))}
        </div>
      </div>

      {/* FORMATIONS */}
      <section className="gsap">
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <div className="formateurs-formations">
            {selectedCategoryName !== 'supervision' && (
              <>
                <PagesFormationsComponent
                  page={'formateurs'}
                  eventsList={eventsList}
                  selectedCategoryId={selectedCategoryId}
                  selectedCategoryName={selectedCategoryName}
                />
                <div className="button">
                  <NavButton to={'/contact'} text={'En savoir plus'} />
                </div>
              </>
            )}
          </div>
        )}
      </section>

      {/* SUPERVISION & ANALYSE */}
      {(!selectedCategoryName || selectedCategoryName === 'supervision') && (
        <div className="formateurs-supervision">
          <h2 className="title-1">Supervision & analyse de pratique pour formateurs</h2>
          <div className="formateurs-supervision--text">
            <p className="formateurs-supervision--text_paragraph text-main thin">
              La supervision Forma’Coach, unique en son genre, permet de prendre du recul sur ses pratiques et
              d’optimiser la qualité des relations avec les stagiaires. Elle a pour ambition une meilleure gestion des
              interactions difficiles pour garantir la qualité de l’apprentissage. Ennayla Arsafi, forme et coache des
              consultants formateurs depuis 2007. Elle est experte de l’ingénierie pédagogique et de formation. Outils
              utilisés en posture coach : Analyse de pratique (APP), Systémique,{' '}
              <abbr title="Process communication">PCM</abbr> , <abbr title="Programmation Neuro Linguistique">PNL</abbr>
              , Intelligence Conflictuelle…
            </p>
            <p className="formateurs-supervision--text_paragraph text-main thin">
              Forma’Coach est expert dans le domaine de la formation, son ambition est d’accompagner la
              professionnalisation des formateurs vers l’exigence qualité Qualiopi.
            </p>
          </div>
          <NavButton to={'/contact'} text={'Nous contacter'} />
        </div>
      )}
    </div>
  );
};

FormateurPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      catTitle: PropTypes.string.isRequired,
      subcategories: PropTypes.array,
    })
  ),
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      subCatTitle: PropTypes.string.isRequired,
      categories: PropTypes.array,
    })
  ),
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string,
      dateEnd: PropTypes.string,
      duration: PropTypes.string,
      location: PropTypes.string.isRequired,
      price: PropTypes.number,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),
};

export default FormateurPage;
