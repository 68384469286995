import axios from 'axios';

// prettier-ignore
import {
  GET_CATEGORIES,
  setCategories,
  GET_SUBCATEGORIES,
  setSubcategories
} from '../actions/categories';
import { startLoading, stopLoading } from '../actions/loading';

const categoriesMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case GET_CATEGORIES: {
      // !!!!! DONT FORGET STORE.DISPATCH MODAFUKA !!!!!
      store.dispatch(startLoading());
      axios
        .get(`${process.env.REACT_APP_API_URL}/categories`)
        .then((response) => {
          if (response.status === 200 && response.data.results > 0) {
            const { categories } = response.data;
            store.dispatch(setCategories(categories));
          }
        })
        .catch((err) => {
          console.trace(err);
        })
        .finally(() => store.dispatch(stopLoading()));
      break;
    }
    case GET_SUBCATEGORIES: {
      // !!!!! DONT FORGET STORE.DISPATCH MODAFUKA !!!!!
      store.dispatch(startLoading());
      axios
        .get(`${process.env.REACT_APP_API_URL}/subcats`)
        .then((response) => {
          if (response.status === 200 && response.data.results > 0) {
            const { subCats } = response.data;
            store.dispatch(setSubcategories(subCats));
          }
        })
        .catch((err) => {
          console.trace(err);
        })
        .finally(() => store.dispatch(stopLoading()));
      break;
    }
    default:
      next(action);
  }
};

export default categoriesMiddleware;
