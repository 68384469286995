import { connect } from 'react-redux';
import HeaderComponent from '../components/HeaderComponent';

import { setPreviousLocation } from '../actions/navigation';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  setPreviousLocation: (payload) => dispatch(setPreviousLocation(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderComponent);
