/* eslint-disable max-len */
import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

// Form validation + sanitize
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import DOMPurify from 'dompurify';
import sanitizeConfig from '../../services/sanitize.config';

// Component
import NavButton from '../../components/Utils/UI/NavButton';
import ActionButton from '../../components/Utils/UI/ActionButton';
import SpinnerLoader from '../../components/Utils/UI/SpinnerLoader';

import './styles.scss';

// Validation Schema
const contactsSchema = yup.object().shape({
  email: yup.string().email().required('Une adresse email est requise'),
  subject: yup.string().required('Merci de sélectionner un sujet'),
  message: yup.string().required('Le contenu du message ne peut être vide'),
});

// prettier-ignore
const ContactsPage = ({
  submitContactForm,
  isLoading,
  apiResponse,
  apiHasReturned,
  resetResponse
}) => {
  // GSAP INIT
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const scrollTl = useRef();

  const scrollItem = (item) => {
    // TIMELINE
    scrollTl.current.fromTo(
      item,
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        duration: 10,
        opacity: 1,
        ease: 'expo',
        scrollTrigger: {
          scrub: 2,
          trigger: item,
          toggleActions: 'play reverse play reverse',
          start: 'top 90%',
          end: 'top 75%',
        },
      },
      '+=1'
    );

    scrollTl.current.play();

    return scrollTl;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // Scroll timeline
      scrollTl.current = gsap.timeline({
        paused: true,
      });

      // Get every line and put it in an array
      gsap.utils.toArray('.gsap').forEach((line) => {
        scrollItem(line);
      });
    }

    return () => {
      isMounted = false;
      scrollTl.current.kill();
    };
  }, []);

  // Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactsSchema),
  });

  // Cleanup form on unmount
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => resetResponse();
  }, []);

  // Handle Errors
  useEffect(() => {
    switch (errors) {
      case errors?.email:
        break;
      case errors?.subject:
        break;
      case errors?.message:
        break;
      default:
        break;
    }
  }, [errors]);

  const handleSubmitContact = (data) => {
    const { email, message, subject } = data;
    const cleanInput = DOMPurify.sanitize(message, sanitizeConfig);

    const cleanData = { email, subject, message: cleanInput };

    submitContactForm(cleanData);
  };

  return (
    <div className="contacts page">
      <h1 className="title-1 gsap">Nous contacter</h1>
      <p className="contacts-text light gsap">
        Vous avez des questions au sujet de nos formations? N’hésitez pas à nous écrire, nous vous répondrons dans
        les plus brefs délais.
      </p>
      {
        // Display message when api returns success or error
        apiHasReturned ? (
          <div className="contacts-apiReturn">
            {apiResponse === 200 ? (
              <>
                <h2>Message envoyé</h2>
                <NavButton to={'/'} text={"Retour à la page d'accueil ?"} />
              </>
            ) : (
              <>
                <h2>Une erreur est survenue</h2>
                <ActionButton action={resetResponse} text={'Vérifier votre message ?'} />
              </>
            )}
          </div>
        ) : (
          // Else display form
          <>
            {/* Hides form when loading to avoid multiple submits */}
            {isLoading ? (
              <SpinnerLoader />
            ) : (
              <form onSubmit={handleSubmit(handleSubmitContact)} className="contacts-form gsap">
                <div className="contacts-form--input contacts-form--input_email">
                  <label htmlFor="email">Email</label>
                  <input {...register('email')} name="email" type="email" />
                  <p className="errors">{errors.email?.message}</p>
                </div>

                <div className="contacts-form--input contacts-form--input_subject">
                  <label htmlFor="subject">Sujet</label>
                  <select {...register('subject')} name="subject">
                    <option value="information">Demande d'information</option>
                    <option value="contact">Prise de contact</option>
                  </select>
                  <p className="errors">{errors.subject?.message}</p>
                </div>

                <div className="contacts-form--input contacts-form--input_message">
                  <label htmlFor="message">Message</label>
                  <textarea {...register('message')} name="message" rows="15" />
                  <p className="errors">{errors.message?.message}</p>
                </div>

                <input type="submit" value="Envoyer" className="contacts-form--btn actionButton" />
              </form>
            )}
          </>
        )
      }
    </div>
  );
};

ContactsPage.propTypes = {
  submitContactForm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  apiResponse: PropTypes.number,
  apiHasReturned: PropTypes.bool,
  resetResponse: PropTypes.func.isRequired,
};

ContactsPage.defaultValue = {
  apiHasReturned: false,
};

export default ContactsPage;
