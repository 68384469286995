/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';

import './styles.scss';

const HomeChart = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [chart, setChart] = useState([
    {
      id: 1,
      title: 'Respecter la parité',
    },
    {
      id: 2,
      title: 'Rechercher l’excellence en respectant le principe de tenségrité',
    },
    {
      id: 3,
      title: "Prendre en considération l'humain dans ses besoins avec bienveillance",
    },
    {
      id: 4,
      title: "Développer son niveau d'intelligence collective",
    },
    {
      id: 5,
      title: "Contribuer à la qualité du travail attendu en suscitant l'autonomie et l’implication de tous",
    },
    {
      id: 6,
      title: 'S’initier à la coopération et savoir la transmettre à son équipe',
    },
    {
      id: 7,
      title:
        'Guider chacun de ses collaborateurs vers la performance, en tenant compte des émotions individuelles comme levier d’action',
    },
    {
      id: 8,
      title: 'Garder le cap malgré un environnement « VICA » (Volatile, Incertain, Complexe, Ambigü)',
    },
  ]);

  const setIndex = (newIndex) => {
    let index = newIndex;
    if (newIndex < 0) {
      index = chart.length - 1;
    }
    // prettier-ignore
    else if (newIndex >= chart.length) {
      index = 0;
    }
    setActiveIndex(index);
  };

  // Listener for keyboard
  useEffect(() => {
    // KEYBOARD
    const handleKeyboard = (event) => {
      if (event.keyCode === 37) {
        setIndex(activeIndex - 1);
      }
      // prettier-ignore
      else if (event.keyCode === 39) {
        setIndex(activeIndex + 1);
      }
    };

    window.addEventListener('keydown', handleKeyboard);

    return () => {
      window.removeEventListener('keydown', handleKeyboard);
    };
  });

  // Auto change carousel slide
  useEffect(() => {
    const timer = setTimeout(() => {
      setIndex(activeIndex + 1);
    }, 4500);

    return () => clearTimeout(timer);
  }, [activeIndex]);

  // SWIPE
  const handlers = useSwipeable({
    onSwipedLeft: () => setIndex(activeIndex + 1),
    onSwipedRight: () => setIndex(activeIndex - 1),
  });

  return (
    <div className="chart">
      <h2 className="chart-title title-1">Qu'est-ce que le leadership éthique ?</h2>
      <p className="chart-desc text-header thin">
        Nous sommes convaincus qu'aujourd'hui, les leaders ont de plus en plus besoin d'intégrer les softskills au
        quotidien afin d'optimiser leurs performances, leur agilité et de contribuer à la qualité de vie au travail{' '}
        <abbr title="Qualité de Vie au Travail">(QVT)</abbr> des collaborateurs. Forma'Coach porte des valeurs éthiques
        à travers ses programmes et propose une charte du leader.
      </p>
      {/* Content */}
      <div className="chart-content">
        {/* Prev */}
        <div className="chart-content--prev" onClick={() => setIndex(activeIndex - 1)} />
        {/* slider */}
        <div className="chart-content--slider" {...handlers}>
          {chart?.map((item) => (
            <div key={item.id} className="chart-content--slider_item">
              <div
                className="chart-content--slider_item-details"
                style={{
                  transform: `translateX(-${activeIndex * 100}%)`,
                }}
              >
                <span
                  className="chart-content--slider_item-details--title title-chart light"
                  style={{
                    transform: `translateY(${item.id === 7 ? '-1.5rem' : '0'})`,
                  }}
                >
                  {item.title}
                </span>
              </div>
            </div>
          ))}
        </div>
        {/* Next */}
        <div className="chart-content--next" onClick={() => setIndex(activeIndex + 1)} />
      </div>
      {/* Index */}
      <div className="chart-index title-3">{activeIndex + 1}</div>
      <div className="chart-borderContainer">
        <div className="chart-borderContainer--border" />
      </div>
    </div>
  );
};

export default HomeChart;
