/// /////////////////////////////////////////////////
// INDENT PROBLEM WITH MAX LENGTH LINE CAREFULL WITH INDENT ON THIS FILE
/* eslint-disable indent */
/// /////////////////////////////////////////////////

/* eslint-disable operator-linebreak */
/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { capitalizeWord } from '../../services/capitalizeWord';

import DisplayFormationsComponent from '../DisplayFormationsComponent';
import FormationCardComponent from '../Formations/FormationCardComponent';
import FormationListItemComponent from '../Formations/FormationListItemComponent';

import EmptyComponent from '../Utils/UI/EmptyComponent';

import './styles.scss';

// prettier-ignore
const PagesFormationsComponent = ({
  eventsList,
  page,
  selectedCategoryId,
  selectedCategoryName
}) => {
  // filter events depending on category id
  // If no category, returns all events with page category from url
  const filteredEvents = useMemo(() => {
    // If category selected
    if (selectedCategoryId !== null) {
      return eventsList?.filter(
        (e) => {
          // If subcategories => event otherwise => atelier
          if (e.subcategories?.length > 0) {
            return (
              e.categories?.find((cat) => cat.catTitle === `${page}`) &&
              e.subcategories?.find((cat) => cat._id === selectedCategoryId)
            );
          }

          return e.subcategory?._id === selectedCategoryId;
        }
      );
    }

    // Else return all events from page category
    return eventsList
      ?.filter((e) => e.categories?.find((cat) => cat.catTitle === `${page}`))
      .filter((event) => event?.subcategories);
  }, [eventsList, selectedCategoryId]);

    return (
      // If category selected, filter events with selected cat
      // Else display all future events
      // If no future event in entreprise, display old events
      <div className="pagesEvents-formations gsap">
        {selectedCategoryId !== null ? (
          <>
            {(selectedCategoryId === null || capitalizeWord(selectedCategoryName) === 'ateliers') && (
              <h3 className="pagesEvents-formations--title title-3 bold italic">
                Les prochaines offres {capitalizeWord(selectedCategoryName)}
              </h3>
            )}
            <div className="pagesEvents-formations--list">
              {filteredEvents?.length > 0 ? (
                <>
                  {filteredEvents?.slice(0, 2).map((event, index) => {
                    // eslint-disable-next-line no-param-reassign
                    index += 1;
                    return <FormationCardComponent key={event._id} event={event} index={index} />;
                  })}
                  {filteredEvents?.slice(2).length > 0 && (
                    <>
                      <h3 className="pagesEvents-formations--list_title title-3 bold italic">
                        Nos autres offres {selectedCategoryName} :
                      </h3>
                      {filteredEvents?.slice(2).map((event, index) => {
                        // eslint-disable-next-line no-param-reassign
                        index += 1;
                        return <FormationListItemComponent key={event._id} event={event} index={index} />;
                      })}
                    </>
                  )}
                </>
              ) : (
                // <EmptyComponent message={`Pas de formation ${selectedCategoryName} programmée pour le moment`} />
                <DisplayFormationsComponent filteredEvents={filteredEvents} eventsList={eventsList} category={page} />
              )}
            </div>
          </>
        ) : (
          <div className="pagesEvents-formations--list">
            {filteredEvents?.length > 0 ? (
              <>
                {filteredEvents?.slice(0, 2).map((event, index) => {
                  // eslint-disable-next-line no-param-reassign
                  index += 1;
                  return <FormationCardComponent key={event._id} event={event} index={index} />;
                })}
                {filteredEvents?.slice(2).length > 0 && (
                  <>
                    <h3 className="pagesEvents-formations--list_title title-3 bold italic">
                      Nos autres offres {selectedCategoryName} :
                    </h3>
                    {filteredEvents?.slice(2).map((event, index) => {
                      // eslint-disable-next-line no-param-reassign
                      index += 1;
                      return <FormationListItemComponent key={event._id} event={event} index={index} />;
                    })}
                  </>
                )}
              </>
            ) : (
              // <EmptyComponent message={`Pas de formation ${selectedCategoryName} programmée pour le moment`} />
              <DisplayFormationsComponent filteredEvents={filteredEvents} eventsList={eventsList} category={page} />
            )}
          </div>
        )}
      </div>
    );
};

PagesFormationsComponent.propTypes = {
  page: PropTypes.string.isRequired,
  selectedCategoryId: PropTypes.string,
  selectedCategoryName: PropTypes.string,
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string,
      dateEnd: PropTypes.string,
      duration: PropTypes.string,
      location: PropTypes.string.isRequired,
      price: PropTypes.number,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),
};

PagesFormationsComponent.defaultProps = {
  selectedCategoryId: '',
  selectedCategoryName: '',
};

export default PagesFormationsComponent;
