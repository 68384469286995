import axios from 'axios';

// prettier-ignore
import {
  GET_EVENTS_LIST,
  setEventsList,
  GET_ATELIERS_LIST,
  setAteliersList
} from '../actions/events';
import { startLoading, stopLoading } from '../actions/loading';

const eventsMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case GET_EVENTS_LIST: {
      // !!!!! DONT FORGET STORE.DISPATCH MODAFUKA !!!!!
      store.dispatch(startLoading());
      axios
        .get(`${process.env.REACT_APP_API_URL}/events/display`)
        .then((response) => {
          if (response.status === 200 && response.data.results > 0) {
            const { events } = response.data;
            store.dispatch(setEventsList(events));
          }
        })
        .catch((err) => {
          console.trace(err);
        })
        .finally(() => store.dispatch(stopLoading()));
      break;
    }
    case GET_ATELIERS_LIST: {
      store.dispatch(startLoading());
      axios
        .get(`${process.env.REACT_APP_API_URL}/ateliers/display`)
        .then((response) => {
          if (response.status === 200 && response.data.results > 0) {
            const { ateliers } = response.data;
            store.dispatch(setAteliersList(ateliers));
          }
        })
        .catch((err) => {
          console.trace(err);
        })
        .finally(() => store.dispatch(stopLoading()));
      break;
    }
    default:
      next(action);
  }
};

export default eventsMiddleware;
