import { connect } from 'react-redux';
import EntreprisePage from '../pages/EntreprisePage';

const mapStateToProps = (state) => ({
  eventsList: [...state.events.eventsList, ...state.events.ateliersList],
  isLoading: state.loading.isLoading,
  categories: state.categories.categories,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EntreprisePage);
