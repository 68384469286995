import { connect } from 'react-redux';
import EventDetailsPage from '../pages/EventDetailsPage';

const mapStateToProps = (state) => ({
  eventsList: state.events.eventsList,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(EventDetailsPage);
