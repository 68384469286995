/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { capitalizeWord } from '../../services/capitalizeWord';

import EmptyComponent from '../Utils/UI/EmptyComponent';
import FormationCardComponent from '../Formations/FormationCardComponent';
import FormationListItemComponent from '../Formations/FormationListItemComponent';

import './styles.scss';

const DisplayFormationsComponent = ({ filteredEvents, eventsList, category }) => {
  const memoedEvents = useMemo(
    () => filteredEvents?.filter((event) => event.categories?.find((cat) => cat?.catTitle === `${category}`)),
    [filteredEvents]
  );

  return (
    <div className="displayFormations">
      <h3 className="displayFormations-subtitle title-3 italic bold">
        Les prochaines formations {capitalizeWord(category)} :
      </h3>
      {/* Map the first 2 events */}
      <div className="displayFormations-cardslist">
        {memoedEvents?.length > 0 ? (
          memoedEvents
            ?.filter((event) => dayjs(event.dateEnd) >= dayjs())
            .slice(0, 2)
            .map((event, index) => {
              // eslint-disable-next-line no-param-reassign
              index += 1;
              return <FormationCardComponent key={event._id} event={event} index={index} />;
            })
        ) : (
          <EmptyComponent message={'Pas de formation pour le moment'} />
        )}
      </div>

      {/* LIST */}
      <div className="displayFormations-itemslist">
        <h3 className="displayFormations-itemslist--title title-3 italic bold">
          Toutes nos formations {memoedEvents?.length > 0 && `${capitalizeWord(category)}`} :
        </h3>
        {memoedEvents?.length > 0 ? (
          <>
            {memoedEvents?.map((event, index) => {
              // eslint-disable-next-line no-param-reassign
              index += 1;
              return <FormationListItemComponent key={event._id} event={event} index={index} />;
            })}
          </>
        ) : (
          <>
            {eventsList?.map((event, index) => {
              // eslint-disable-next-line no-param-reassign
              index += 1;
              return <FormationListItemComponent key={event._id} event={event} index={index} />;
            })}
          </>
        )}
      </div>
    </div>
  );
};

DisplayFormationsComponent.propTypes = {
  category: PropTypes.string.isRequired,

  // FULL LIST OF EVENTS
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string,
      dateEnd: PropTypes.string,
      location: PropTypes.string.isRequired,
      price: PropTypes.number,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),

  // FULL LIST OF EVENTS
  filteredEvents: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string,
      dateEnd: PropTypes.string,
      location: PropTypes.string.isRequired,
      price: PropTypes.number,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),
};

export default DisplayFormationsComponent;
