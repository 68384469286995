import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import dompurify from 'dompurify';

import './styles.scss';

import sanitizeConfig from '../../../../services/sanitize.config';

const DangerouslyDisplayHTMLComponent = ({ content }) => {
  const sanitizedContent = dompurify.sanitize(content, sanitizeConfig);
  const elRef = useRef();

  useEffect(() => {
    if (elRef?.current) {
      elRef.current.style.color = '#FFF';
    }

    // Might be improved
    if (elRef?.current?.children?.length > 0) {
      const children = elRef?.current?.children;

      // If element has children, set style
      Array.from(children).forEach((child) => {
        child.style.color = 'white';

        // Get children
        if (child.children.length > 0) {
          const items = child.children;
          Array.from(items).forEach((el) => {
            el.style.color = 'white';

            // If ul / ol => get li item
            if (el.children.length > 0) {
              const listItem = el.children;
              Array.from(listItem).forEach((item) => {
                item.style.color = 'white';

                // For random span in li elements...
                if (item.children.length > 0) {
                  const spanItem = item.children;

                  Array.from(spanItem).forEach((spanEl) => {
                    spanEl.style.color = 'white';
                  });
                }
              });
            }
          });
        }
      });
    }
  }, [content]);

  return <div ref={elRef} className="dangerous-html" dangerouslySetInnerHTML={{ __html: `${sanitizedContent}` }} />;
};

DangerouslyDisplayHTMLComponent.propTypes = {
  content: PropTypes.string,
};

export default DangerouslyDisplayHTMLComponent;
