/* eslint-disable import/prefer-default-export */
import { gsap } from 'gsap';

export const gsapAnimate = (el, from, to) => {
  if (!el) return;

  const animate = () => {
    if (from && to) {
      gsap.fromTo(el, from, to);
    }
    // prettier-ignore
    else {
      gsap.to(el, from);
    }
  };

  animate();
};
