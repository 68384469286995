import { connect } from 'react-redux';
import CoachingPage from '../pages/CoachingPage';

const mapStateToProps = (state) => ({
  eventsList: state.events.eventsList,
  isLoading: state.loading.isLoading,
  categories: state.categories.categories,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CoachingPage);
