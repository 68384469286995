/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

// Form validation + Sanitizer
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import DOMPurify from 'dompurify';
import sanitizeConfig from '../../services/sanitize.config';

// Component
import NavButton from '../../components/Utils/UI/NavButton';
import ActionButton from '../../components/Utils/UI/ActionButton';
import SpinnerLoader from '../../components/Utils/UI/SpinnerLoader';

import './styles.scss';

// Validation Schema
const contactsSchema = yup.object().shape({
  event: yup.string().required('Merci de sélectionner une formation'),
  firstname: yup
    .string()
    .required('Merci de renseigner un prénom')
    .max(24, 'Le prénom doit contenir moins de 24 caractères alphanumériques'),
  lastname: yup
    .string()
    .required('Merci de renseigner un nom')
    .max(24, 'Le nom doit contenir moins de 24 caractères alphanumériques'),
  email: yup.string().email().required('Une adresse email est requise'),
  entreprise: yup.string().max(24, "Le nom de l'entreprise doit contenir moins de 24 caractères alphanumériques"),
  phone: yup.string(),
  role: yup.string().required('Merci de renseigner ce champ'),
  about: yup.string().required('Merci de renseigner ce champ'),
  message: yup.string().required('Le contenu du message ne peut être vide'),
});

// prettier-ignore
const InscriptionPage = ({
  eventsList,
  submitInscriptionForm,
  isLoading,
  apiResponse,
  apiHasReturned,
  resetResponse,
}) => {
  const [displayError, setDisplayError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedEvent, setSelectedEvent] = useState(null);

  const { id } = useParams();
  // Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactsSchema),
  });

  // Cleanup form on unmount
  useEffect(() => {
    const eventFound = eventsList?.find((e) => e._id === id);
    setSelectedEvent(eventFound);
    return () => resetResponse();
  }, []);

  const handleSubmitInscription = (data) => {
    // Extract and clean fields
    const {
      firstname, lastname, message, phone, email, entreprise, role, about, event
    } = data;

    const cleanFirstname = DOMPurify.sanitize(firstname, sanitizeConfig);
    const cleanLastname = DOMPurify.sanitize(lastname, sanitizeConfig);
    const cleanMessage = DOMPurify.sanitize(message, sanitizeConfig);
    const cleanPhone = DOMPurify.sanitize(phone, sanitizeConfig);
    const cleanEmail = DOMPurify.sanitize(email, sanitizeConfig);
    const cleanEntreprise = DOMPurify.sanitize(entreprise, sanitizeConfig);
    const cleanRole = DOMPurify.sanitize(role, sanitizeConfig);
    const cleanAbout = DOMPurify.sanitize(about, sanitizeConfig);
    const cleanEvent = DOMPurify.sanitize(event, sanitizeConfig);

    const cleanInputs = {
      firstname: cleanFirstname,
      lastname: cleanLastname,
      message: cleanMessage,
      phone: cleanPhone || 'Non renseigné',
      email: cleanEmail,
      entreprise: cleanEntreprise || 'Non renseigné',
      role: cleanRole,
      about: cleanAbout,
      event: cleanEvent,
    };

    // Check if event exists
    const eventFound = eventsList?.find((e) => e._id === event);

    if (!eventFound) {
      setDisplayError(
        "L'événement renseigné semble ne pas exister, merci de réitérer. Si le problème persiste, merci de nous contacter directement."
      );
    }

    const {
      dateStart, dateEnd, title, duration
    } = eventFound;

    if (!dateStart && !dateEnd) {
      submitInscriptionForm({
        ...cleanInputs,
        duration,
        title,
      });
    }

    if (!duration) {
      submitInscriptionForm({
        ...cleanInputs,
        dateStart,
        dateEnd,
        title,
      });
    }
  };

  return (
    <div className="inscription page">
      <h1 className="title-1">Vous inscrire</h1>
      <p className="inscription-text light">
        Afin de vous recevoir dans les meilleurs conditions, merci de remplir le formulaire d’inscription.
      </p>
      {
        // Display message when api returns success or error
        apiHasReturned ? (
          <div className="inscription-apiReturn">
            {apiResponse ? (
              <>
                <h2>Message envoyé</h2>
                <NavButton to={'/'} text={"Retour à la page d'accueil ?"} />
              </>
            ) : (
              <>
                <h2>Une erreur est survenue</h2>
                <ActionButton action={resetResponse} text={'Vérifier votre message ?'} />
              </>
            )}
          </div>
        ) : (
          // Else display form
          <>
            {/* Hides form when loading to avoid multiple submits */}
            {isLoading ? (
              <SpinnerLoader />
            ) : (
              <form onSubmit={handleSubmit(handleSubmitInscription)} className="inscription-form">
                {/* FORMATION */}
                <div className="inscription-form--input inscription-form--input_event">
                  <label htmlFor="event">Formation :</label>
                  <select {...register('event')} name="event">
                    {eventsList?.map((e) => {
                      if (e._id === id) {
                        return (
                          <option key={e._id} value={e._id} selected>
                            {e.title}
                          </option>
                        );
                      }
                      return (
                        <option key={e._id} value={e._id}>
                          {e.title}
                        </option>
                      );
                    })}
                  </select>
                  <p className="errors">{errors.event?.message}</p>
                </div>

                {/* NOM */}
                <div className="inscription-form--input inscription-form--input_lastname">
                  <label htmlFor="lastname">Nom *</label>
                  <input {...register('lastname')} name="lastname" type="text" />
                  <p className="errors">{errors.lastname?.message}</p>
                </div>

                {/* PRENOM */}
                <div className="inscription-form--input inscription-form--firstname">
                  <label htmlFor="firstname">Prénom *</label>
                  <input {...register('firstname')} name="firstname" type="text" />
                  <p className="errors">{errors.firstname?.message}</p>
                </div>

                {/* EMAIL */}
                <div className="inscription-form--input inscription-form--input_email">
                  <label htmlFor="email">Email *</label>
                  <input {...register('email')} name="email" type="email" />
                  <p className="errors">{errors.email?.message}</p>
                </div>

                {/* ENTREPRISE */}
                <div className="inscription-form--input inscription-form--entreprise">
                  <label htmlFor="entreprise">Nom de l'entreprise</label>
                  <input {...register('entreprise')} name="entreprise" type="text" />
                  <p className="errors">{errors.entreprise?.message}</p>
                </div>

                {/* TELEPHONE */}
                <div className="inscription-form--input inscription-form--phone">
                  <label htmlFor="phone">Numéro de téléphone</label>
                  <input {...register('phone')} name="phone" type="tel" />
                  <p className="errors">{errors.phone?.message}</p>
                </div>

                {/* JE SUIS */}
                <div className="inscription-form--input inscription-form--input_role">
                  <label htmlFor="role">Je suis *</label>
                  <select {...register('role')} name="role">
                    <option value="entreprise" selected>
                      Entreprise
                    </option>
                    <option value="particulier">Particulier</option>
                  </select>
                  <p className="errors">{errors.role?.message}</p>
                </div>

                {/* JE SOUHAITE */}
                <div className="inscription-form--input inscription-form--input_about">
                  <label htmlFor="about">Je souhaite *</label>
                  <select {...register('about')} name="about">
                    <option value="information">Obtenir des informations</option>
                    <option value="inscription" selected>
                      M'inscrire à la formation
                    </option>
                  </select>
                  <p className="errors">{errors.about?.message}</p>
                </div>

                {/* MESSAGE */}
                <div className="inscription-form--input inscription-form--input_message">
                  <label htmlFor="message">Message *</label>
                  <textarea {...register('message')} name="message" rows="15" />
                  <p className="errors">{errors.message?.message}</p>
                </div>

                {/* Error message */}
                {displayError && <div className="inscription-form--error">{displayError}</div>}

                {/* SUBMIT */}
                <input type="submit" value="Envoyer" className="inscription-form--btn actionButton" />
              </form>
            )}
          </>
        )
      }
    </div>
  );
};

InscriptionPage.propTypes = {
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string.isRequired,
      dateEnd: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),
  submitInscriptionForm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  apiResponse: PropTypes.number.isRequired,
  apiHasReturned: PropTypes.bool.isRequired,
  resetResponse: PropTypes.func.isRequired,
};

export default InscriptionPage;
