/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Handle Time
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr';

import { MdTimer } from 'react-icons/md';

import NavButton from '../../components/Utils/UI/NavButton';
import DangerouslyDisplayHTMLComponent from '../../components/Utils/UI/DangerouslyDisplayHTMLComponent';
import SpinnerLoader from '../../components/Utils/UI/SpinnerLoader';
import AteliersListItem from '../../components/AteliersComponent/AteliersListItem';

import './styles.scss';

dayjs.extend(relativeTime);
dayjs.locale('fr');

const AtelierDetailsPage = ({ ateliersList }) => {
  // State
  const [atelierDetails, setAtelierDetails] = useState({});

  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgLink, setImgLink] = useState(null);

  const { id, index } = useParams();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo(0, 0);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (
      atelierDetails?.categories?.length > 0
    ) {
      setImgLink(
        `/imgs/categories/${atelierDetails?.categories[0]?.catTitle}-${index}-600.jpg`
      );
    }
    // prettier-ignore
    else {
      setImgLink(`/imgs/categories/${atelierDetails?.category?.catTitle}-${index}-600.jpg`);
    }
  }, [atelierDetails, id]);

  useEffect(() => {
    setAtelierDetails(ateliersList?.find((event) => event._id === id));
  }, [ateliersList, id]);

  return (
    <div className="atelierDetails page">
      <h1 className="title-2">{atelierDetails?.title}</h1>
      <div className="atelierDetails-details">
        {/* Left col */}
        <div className="atelierDetails-details--left">
          {/* Description */}
          <p className="bold">Programme&thinsp;:</p>

          <DangerouslyDisplayHTMLComponent
            className="atelierDetails-details--left_desc light"
            content={atelierDetails?.program}
          />
          {/* Duration | Location | Certification */}
          <div className="atelierDetails-details--left_infos">
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1rem'
              }}
            >
              <div className="formation-card--details_infos__duration">
                <MdTimer />
                {`${atelierDetails?.duration}.`}
              </div>
              <div className="formation-card--details_infos__location">| {atelierDetails?.location}</div>
              {atelierDetails?.certification && (
                <div className="formation-card--details_infos__certification">| Certifiante</div>
              )}
            </span>{' '}
          </div>
          {/* prerequisites */}
          <div className="atelierDetails-details--left_prerequisites">
            <p className="bold">Pré-requis&thinsp;:</p>
            <p className="text-main thin">{atelierDetails?.prerequisites}</p>
          </div>
          {/* Objectives */}
          <div className="atelierDetails-details--left_objectives">
            <p className="bold">Objectifs&thinsp;:</p>
            <DangerouslyDisplayHTMLComponent content={atelierDetails?.objectives} />
          </div>
          {/* Modalities */}
          <div className="atelierDetails-details--left_modalities">
            <p className="bold">Modalités&thinsp;:</p>
            {/* <p className="text-main thin">{atelierDetails?.modalities}</p> */}
            <DangerouslyDisplayHTMLComponent
              className="eventDetails-details--left_desc light"
              content={atelierDetails?.modalities}
            />
          </div>
          {/* Price */}
          <div className="atelierDetails-details--left_price extralight">Prix sur demande</div>
        </div>
        {/* Right col */}
        <div className="atelierDetails-details--right">
          <div className="atelierDetails-details--right_img">
            {!imgLoaded && <SpinnerLoader />}
            {imgLink && (
              <img src={imgLink} alt={`${atelierDetails?.category?.catTitle}`} onLoad={() => setImgLoaded(true)} />
            )}
          </div>
        </div>
      </div>
      <div className="atelierDetails-details--right_btn">
        <NavButton to={`/inscription/${atelierDetails?._id}`} text="Vous inscrire" />
      </div>
      {/* OTHER FORMATIONS */}
      <div className="eventsList">
        <h2 className="title-2">Nos autres ateliers</h2>

        {ateliersList
          ?.filter((atelier) => atelier?._id !== id)
          .map((atelier, cpt) => (
            <AteliersListItem key={atelier?._id} atelier={atelier} index={index === 1 ? 2 : 1} />
          ))}
      </div>
    </div>
  );
};

AtelierDetailsPage.propTypes = {
  ateliersList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      duration: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired
    })
  )
};

export default AtelierDetailsPage;
