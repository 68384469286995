import PropTypes from 'prop-types';

import './styles.scss';

const ActionButton = ({ action, text }) => {
  const handleAction = () => {
    action();
  };

  return (
    <div className="actionButton" onClick={handleAction}>
      {text}
    </div>
  );
};

ActionButton.propTypes = {
  action: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default ActionButton;
