/* eslint-disable max-len */
import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import './styles.scss';

import NavButton from '../../components/Utils/UI/NavButton';

const RessourcesPage = () => {
  // GSAP INIT
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const scrollTl = useRef();

  const scrollItem = (item) => {
    // TIMELINE
    scrollTl.current.fromTo(
      item,
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        duration: 10,
        opacity: 1,
        ease: 'expo',
        scrollTrigger: {
          scrub: 2,
          trigger: item,
          toggleActions: 'play reverse play reverse',
          start: 'top 90%',
          end: 'top 75%',
        },
      },
      '+=1'
    );

    scrollTl.current.play();

    return scrollTl;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // Scroll timeline
      scrollTl.current = gsap.timeline({
        paused: true,
      });

      // Get every line and put it in an array
      gsap.utils.toArray('.gsap').forEach((line) => {
        scrollItem(line);
      });
    }

    return () => {
      isMounted = false;
      scrollTl.current.kill();
    };
  }, []);

  return (
    <div className="ressources page">
      <section>
        <div className="ressources-formateurs">
          <h2 className="title-1 gsap">Sourcing de formateurs</h2>
          <p className="text-header thin gsap">
            Vous êtes une entreprise ou un campus université d’entreprise, un organisme de formation, une grande école
            et vous rencontrez des difficultés pour trouver un consultant formateur ou coach expérimenté ? Forma’Coach
            propose de vous accompagner pour assurer vos formations en déployant ses experts en France métropolitaine et
            ce, quel que soit le domaine. Les formateurs et coachs professionnels pluridisciplinaires de Forma’Coach
            sont tous certifiés.
          </p>
          <div className="ressources-formateurs--list gsap">
            {/* Left col */}
            <div className="ressources-formateurs--list_left">
              <h3 className="ressources-formateurs--list_left-title title-3">Exemples de champs d'expertise :</h3>
              <ul>
                <li className="ressources-formateurs--list_left-item">Management</li>
                <li className="ressources-formateurs--list_left-item">Développement personnel</li>
                <li className="ressources-formateurs--list_left-item">Relations Humaines</li>
                <li className="ressources-formateurs--list_left-item">Droit social</li>
                <li className="ressources-formateurs--list_left-item">Paie</li>
                <li className="ressources-formateurs--list_left-item">Formation de formateurs</li>
                <li className="ressources-formateurs--list_left-item">Formation de tuteurs</li>
                <li className="ressources-formateurs--list_left-item">...</li>
              </ul>
            </div>

            {/* Right col */}
            <div className="ressources-formateurs--list_right">
              <h3 className="ressources-formateurs--list_right-title title-3">Exemples d'outils :</h3>
              <ul>
                <li className="ressources-formateurs--list_right-item">
                  Process communication <abbr title="Process Communication">(PCM)</abbr>
                </li>
                <li className="ressources-formateurs--list_right-item">
                  Programmation Neuro Linguistique <abbr title="Programmation Neuro Linguistique">(PNL)</abbr>
                </li>
                <li className="ressources-formateurs--list_right-item">Résilience</li>
                <li className="ressources-formateurs--list_right-item">Intelligence conflictuelle</li>
                <li className="ressources-formateurs--list_right-item">Co-développement</li>
                <li className="ressources-formateurs--list_right-item">Coaching somatique</li>
                <li className="ressources-formateurs--list_right-item">Pratiques narratives</li>
                <li className="ressources-formateurs--list_right-item">...</li>
              </ul>
            </div>
          </div>
          <div className="gsap">
            <NavButton to={'/contact'} text={'Nous contacter'} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default RessourcesPage;
