import { useEffect } from 'react';
import PropTypes from 'prop-types';

// prettier-ignore
import {
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

// Components
import Header from '../../containers/headerContainer';
import Footer from '../FooterComponent';
// import SpinnerLoader from '../Utils/UI/SpinnerLoader';

import LoadingWave from '../../containers/Utils/loadingContainer';

// Pages
import AboutPage from '../../pages/AboutPage';
import ContactsPage from '../../containers/contactPageContainer';
import InscriptionPage from '../../containers/inscriptionPageContainer';
import RessourcesPages from '../../pages/SourcingPage';
import CoachingPage from '../../containers/coachingPageContainer';
import EntreprisePage from '../../containers/entreprisePageContainer';
import FormateurPage from '../../containers/formateurPageContainer';
import HomePage from '../../pages/HomePage';
import MentionsLegalesPage from '../../pages/MentionsLegalesPages';
import EventDetailsPage from '../../containers/eventDetailsPageContainer';
import AtelierDetailsPage from '../../containers/atelierDetailsPageContainer';
// import Footer from '../FooterComponent';

import './styles.scss';

// prettier-ignore
const Formacoach = ({
  setCurrentLocation,
  getEventsList,
  getAteliersList,
  isFirstLoading,
  getCategories,
  getSubcategories,
}) => {
  const location = useLocation();

  useEffect(() => {
    setCurrentLocation(location);
    window.scrollTo(-500, 0);
  }, [location]);

  useEffect(() => {
    getEventsList();
    getAteliersList();
    getCategories();
    getSubcategories();
  }, []);

  return (
    <>
      {/* {isFirstLoading && <LoadingWave />} */}
      <div className="formacoach">
        <Header />
        <div className="formacoach-container">
          <Routes key="route">
            <Route path="/" exact element={<HomePage />} />
            <Route path="/entreprises" exact element={<EntreprisePage />} />
            <Route path="/formateurs" exact element={<FormateurPage />} />
            <Route path="/coachs" exact element={<CoachingPage />} />
            <Route path="/sourcing" exact element={<RessourcesPages />} />
            <Route path="/events/:id/:index" exact element={<EventDetailsPage />} />
            <Route path="/ateliers/:id/:index" exact element={<AtelierDetailsPage />} />
            <Route path="/about" exact element={<AboutPage />} />
            <Route path="/contact" exact element={<ContactsPage />} />
            <Route path="/inscription/:id" exact element={<InscriptionPage />} />
            <Route path="/mentions-legales" exact element={<MentionsLegalesPage />} />
            {/* 404 */}
            <Route path="*" element={<HomePage />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </>
  );
};

Formacoach.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFirstLoading: PropTypes.bool.isRequired,
  setCurrentLocation: PropTypes.func.isRequired,
  getEventsList: PropTypes.func.isRequired,
  getAteliersList: PropTypes.func.isRequired,
  eventsList: PropTypes.array.isRequired,
  getCategories: PropTypes.func.isRequired,
  getSubcategories: PropTypes.func.isRequired
};

export default Formacoach;
