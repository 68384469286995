import { SET_CATEGORIES, SET_SUBCATEGORIES } from '../actions/categories';

export const initialState = {
  categories: [],
  subcategories: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_SUBCATEGORIES:
      return {
        ...state,
        subcategories: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
