import { SET_EVENTS_LIST, SET_ATELIERS_LIST } from '../actions/events';

export const initialState = {
  eventsList: [],
  ateliersList: [],
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_EVENTS_LIST:
      return {
        ...state,
        eventsList: action.payload,
      };
    case SET_ATELIERS_LIST:
      return {
        ...state,
        ateliersList: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
