/* eslint-disable max-len */ // for SVG purposes
// prettier-ignore
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import SpinnerLoader from '../SpinnerLoader';

import Logo from '../../../../assets/logo-icon.png';
import './styles.scss';

const LoadingWave = ({ startFirstLoading }) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  // Refs timelines
  const waveAnimTl = useRef();
  const textAnimTl = useRef();

  // prettier-ignore
  const value1 = `M0,0c227,0,1717,0,${window.innerWidth},0c0,45,0,120,0,120C1186.579,33,421,206,0,114C0,84,0,0,0,0z`;
  // prettier-ignore
  const value2 = `M0,0c227,0,1717,0,${window.innerWidth},0c0,45,0,1080,0,1080c-727,0-1465,0-${window.innerWidth},0C0,1050,0,0,0,0z`;

  // ANIMATION STEPS
  // 1 - WAVE TO FULLSCREEN
  // 2 - DISPLAY LOADING TEXT
  // 3 - REVERSE LOADING TEXT
  // 4 - REVERSE WAVE ANIMATION
  // 5 - stopLoading()
  useEffect(() => {
    // DISABLE SCROLLING DURING THE ANIMATION
    document.body.style.overflow = 'hidden';

    waveAnimTl.current = gsap.timeline({
      paused: true,
      onComplete: () => {
        textAnimTl.current.play();
      },
      onReverseComplete: () => {
        waveAnimTl.current.kill();
        textAnimTl.current.kill();
        startFirstLoading();
      },
    });
    textAnimTl.current = gsap.timeline({
      paused: true,
      onComplete: () => {
        waveAnimTl.current.reverse();
      },
    });

    // 1 - WAVE BACKGROUND ANIM
    waveAnimTl.current
      .to('.formacoach', { opacity: 0 })
      .to(window, {
        duration: 1,
        ease: 'expo',
        scrollTo: {
          x: 0,
          y: 0,
        },
      })
      .to('.loading', { opacity: 1, duration: 0.5 })
      .fromTo(
        '.loading-wave--path',
        {
          attr: { d: value2 },
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.8,
          ease: 'power2.in',
        }
      )
      .to(
        '.loading-wave--path',
        {
          duration: 1,
          y: -50,
          attr: { d: value1 },
          ease: 'power2',
        },
        '>0.5'
      )
      .to('.formacoach', { opacity: 1 });

    // 2 - LOADING TEXT ANIM
    textAnimTl.current
      .to('.loading-text', {
        opacity: 1,
        duration: 1,
      })
      .to('.loading-text', {
        opacity: 0,
        duration: 1.5,
        delay: 1,
      });

    waveAnimTl.current.play();

    return () => {
      waveAnimTl.current.kill();
      textAnimTl.current.kill();
      document.body.style.overflow = 'inherit';
    };
  }, []);

  return (
    <div className="loading">
      <div className="loading-text">
        <div className="loading-text_logo">
          {!imgLoaded && <SpinnerLoader />}
          <img src={Logo} alt="logo-formacoach" onLoad={() => setImgLoaded(true)} />
        </div>
        <h1 className="title-1">LOADING</h1>
      </div>
      <svg
        className="loading-wave"
        xmlns="http://www.w3.org/2000/svg"
        // viewBox="0 0 1920 1080"
        preserveAspectRatio="xMidYMax slice"
        x="0"
        y="0"
      >
        <linearGradient
          id="main-gradient"
          gradientUnits="userSpaceOnUse"
          x1="375.4756"
          y1="539.4741"
          x2="1826.5214"
          y2="-678.0979"
        >
          {/* <stop offset="0.0594" style={{ stopColor: '#941B80' }} />
          <stop offset="0.0607" style={{ stopColor: '#941B80' }} />
          <stop offset="0.2203" style={{ stopColor: '#652171' }} />
          <stop offset="0.3615" style={{ stopColor: '#422567' }} />
          <stop offset="0.4777" style={{ stopColor: '#2E2760' }} />
          <stop offset="0.5545" style={{ stopColor: '#26285E' }} />
          <stop offset="0.6732" style={{ stopColor: '#243871' }} />
          <stop offset="0.9141" style={{ stopColor: '#1F60A4' }} />
          <stop offset="1" style={{ stopColor: '#1D70B7' }} /> */}
          <stop offset="0.05" style={{ stopColor: '#1F60A4' }} />
          <stop offset="0.25" style={{ stopColor: '#243871' }} />
          <stop offset="0.42" style={{ stopColor: '#25275e' }} />
          <stop offset="0.75" style={{ stopColor: '#652171' }} />
          <stop offset="0.92" style={{ stopColor: '#941B80' }} />
        </linearGradient>
        <path fill="url(#main-gradient)" className="loading-wave--path" d={`${value1}`} />
      </svg>
    </div>
  );
};

LoadingWave.propTypes = {
  startFirstLoading: PropTypes.func.isRequired,
};

export default LoadingWave;
