/* eslint-disable no-underscore-dangle */
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Handle Time
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr';

import { MdTimer, MdAccessibleForward } from 'react-icons/md';

import FormationListItemComponent from '../../components/Formations/FormationListItemComponent';
import NavButton from '../../components/Utils/UI/NavButton';
import DangerouslyDisplayHTMLComponent from '../../components/Utils/UI/DangerouslyDisplayHTMLComponent';

import './styles.scss';
import SpinnerLoader from '../../components/Utils/UI/SpinnerLoader';

dayjs.extend(relativeTime);
dayjs.locale('fr');
const EventDetailsPage = ({ eventsList }) => {
  // State
  const [imgLoaded, setImgLoaded] = useState(false);
  const [pictoLoaded, setPictoLoaded] = useState(false);
  const [imgLink, setImgLink] = useState(null);

  const [eventDetails, setEventDetails] = useState({});

  const { id, index } = useParams();

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo(0, 0);
    }

    return () => {
      window.scrollTo(0, 0);
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    setEventDetails(eventsList?.find((event) => event._id === id));
  }, [eventsList, id]);

  useEffect(() => {
    if (
      eventDetails?.categories?.length > 0
    ) {
      setImgLink(
        `/imgs/categories/${eventDetails?.categories[0]?.catTitle}-${index}-600.jpg`
      );
    }
    // prettier-ignore
    else {
      setImgLink(`/imgs/categories/${eventDetails?.category?.catTitle}-${index}-600.jpg`);
    }
  }, [eventDetails, id]);

  return (
    <div className="eventDetails page">
      <div>
        <h1 className="title-2">{eventDetails?.title}</h1>
        <div className="eventDetails-details">
          {/* Left col */}
          <div className="eventDetails-details--left">
            <p className="bold">Programme:</p>
            {/* Programme */}
            <DangerouslyDisplayHTMLComponent
              className="eventDetails-details--left_desc light"
              content={eventDetails?.program}
            />
            {/* prerequisites */}
            <div className="eventDetails-details--left_prerequisites">
              <p className="bold">Pré-requis:</p>
              <p className="text-main thin">{eventDetails?.prerequisites}</p>
            </div>
            {/* Modalitites */}
            <div className="eventDetails-details--left_modalities">
              <p className="bold">Modalités:</p>
              <DangerouslyDisplayHTMLComponent
                className="eventDetails-details--left_desc light"
                content={eventDetails?.modalities}
              />
              {/* <p className="text-main thin">{eventDetails?.modalities}</p> */}
            </div>
            {/* Handicap */}
            <div className="eventDetails-details--left_handicap">
              <MdAccessibleForward className="eventDetails-details--left_handicap-icon" />
              <p className="text-main thin">
                Pour les personnes en situation d'handicap des solutions et lieux spécifiques peuvent être envisagés sur
                demande. Nous contacter.
              </p>
            </div>
            {/* Dates */}
            <div className="eventDetails-details--left_dates">
              <div
                className="bold"
                style={{
                  display: 'inline-flex',
                  alignItems: 'baseline',
                  gap: '1rem'
                }}
              >
                Prochaine session :{' '}
              </div>
              {dayjs(eventDetails?.dateEnd) <= dayjs() ? (
                <p>DATES À VENIR ET SUR DEMANDE </p>
              ) : (
                <div className="eventDetails-details--left_dates-date">
                  du<h3> {dayjs(eventDetails?.dateStart).format('DD MMMM')}</h3> au
                  <h3>{dayjs(eventDetails?.dateEnd).format('DD MMMM YYYY')}</h3>
                </div>
              )}
            </div>
            {/* Duration | Location | Certification */}
            <div className="eventDetails-details--left_infos">
              <div
                style={{
                  display: 'flex',
                  gap: '0.5rem',
                  alignItems: 'baseline'
                }}
              >
                <div className="eventDetails-details--left_infos__duration bold">
                  <MdTimer />
                  {`${dayjs(eventDetails?.dateEnd).add(1, 'day').to(eventDetails?.dateStart, true)}`}
                </div>
                <div className="eventDetails-details--left_infos__location">| {eventDetails?.location}</div>
                {eventDetails?.certification && (
                  <div className="eventDetails-details--left_infos__certification">
                    |
                    <div>
                      {!pictoLoaded && <SpinnerLoader />}
                      <img
                        className="eventDetails-details--left_infos__certification-picto"
                        src={'/icons/certification-icon.svg'}
                        alt={'Formation certifiante'}
                        onLoad={() => setPictoLoaded(true)}
                      />
                    </div>
                    Certifiante*
                  </div>
                )}
              </div>{' '}
            </div>
            {eventDetails?.certification && (
              <p className="eventDetails-details--left_infos__certification text-legend light">
                * Faire passer le test psychométrique à une personne de son entourage + 2 heures de certification visant
                à valider la connaissance du modèle et l’aisance dans le rendu de feedback du test face au master EUROPE
                IWD (Français et Anglais) . Post- certification: Restitution du test à son témoin.
              </p>
            )}
            {/* Price */}
            <div className="eventDetails-details--left_infos">
              <div style={{ display: 'inline-flex', alignItems: 'baseline', gap: '0.5rem' }}>
                <p className="bold">Prix inter-entreprise :</p>
                <div className="eventDetails-details--left_infos__price extralight">{eventDetails?.price} € HT</div>
              </div>
              {eventDetails?.intra && (
                <div style={{ display: 'inline-flex', alignItems: 'baseline', gap: '0.5rem' }}>
                  <p className="bold">Prix intra-entreprise : </p>
                  <p>Prix sur demande</p>
                </div>
              )}
            </div>
          </div>
          {/* //////////////////////////////////////////////////////////////////////// */}
          {/* Right col */}
          <div className="eventDetails-details--right">
            <div className="eventDetails-details--right_img">
              {!imgLoaded && <SpinnerLoader />}
              {imgLink && (
                <img src={imgLink} alt={`${eventDetails?.category?.catTitle}`} onLoad={() => setImgLoaded(true)} />
              )}
            </div>
            {/* Objectives */}
            <div className="eventDetails-details--right_objectives">
              <p className="bold">Objectifs:</p>
              <DangerouslyDisplayHTMLComponent content={eventDetails?.objectives} />
            </div>
          </div>
        </div>
        <div className="eventDetails-details--right_btn">
          <NavButton to={`/inscription/${eventDetails?._id}`} text="Vous inscrire" />
          {/* Satisfaction */}
          <div className="eventDetails-details--right_satisfaction">
            <span>NOS RESULTATS QUALITÉ : 93% DE STAGIAIRES SATISFAITS**</span>
            <span className="text-legend light">** Résultats 2023</span>
          </div>
        </div>
      </div>
      {/* OTHER FORMATIONS */}
      <div className="eventsList">
        <h2 className="title-2">Nos autres formations</h2>

        {eventsList
          ?.filter((event) => event?._id !== id)
          .map((event) => (
            <FormationListItemComponent key={event?._id} event={event} />
          ))}
      </div>
    </div>
  );
};

EventDetailsPage.propTypes = {
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string.isRequired,
      dateEnd: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired
    })
  )
};

export default EventDetailsPage;
