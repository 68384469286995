/* eslint-disable max-len */
import { useState, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import './styles.scss';
import ValuesComponent from '../../components/ValuesComponent';
import ValuesMobileComponent from '../../components/ValuesMobileComponent';

import SpinnerLoader from '../../components/Utils/UI/SpinnerLoader';

const AboutPage = () => {
  const [imgLoaded, setImgLoaded] = useState(false);

  const mask = `M 0.5 0.5 c 0 0 0 491.517 0 600 c 420 91.974 1186.21 -80.977 ${window.innerWidth} 5.998 V 0.5 H 0.5 Z`;

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const scrollTl = useRef();

  const scrollItem = (item) => {
    // TIMELINE
    scrollTl.current.fromTo(
      item,
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        duration: 10,
        opacity: 1,
        ease: 'expo',
        scrollTrigger: {
          scrub: 2,
          trigger: item,
          toggleActions: 'play reverse play reverse',
          start: 'top 90%',
          end: 'top 75%',
        },
      },
      '+=1'
    );

    scrollTl.current.play();

    return scrollTl;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // Scroll timeline
      scrollTl.current = gsap.timeline({
        paused: true,
      });

      // Get every line and put it in an array
      gsap.utils.toArray('.gsap').forEach((line) => {
        scrollItem(line);
      });
    }

    return () => {
      isMounted = false;
      scrollTl.current.kill();
    };
  }, []);

  return (
    <div className="about">
      {/* HEADER */}
      <div className="about-img">
        {!imgLoaded && <SpinnerLoader />}
        <div className="about-img--quote">
          <h3 className="about-img--quote_text title-3 bold">
            « Pour remotiver vos équipes, demandez-vous ce qui les a démotivés »
          </h3>
          <p>Ennayla Arsafi</p>
        </div>
        {/* <img src="/imgs/formations-1-600.jpg" alt="formation" onLoad={() => setImgLoaded(true)} /> */}
        <svg viewBox={`0 0 ${window.innerWidth} 600`} preserveAspectRatio="xMidYMid slice">
          <defs>
            <clipPath id="header-btm-clip">
              <path fill="#FFFFFF" stroke="#FF00FF" className="header-wave--path" d={`${mask}`} />
            </clipPath>
          </defs>
        </svg>
      </div>

      {/* CONTENT */}
      <div className="about-content">
        <div className="about-content--top gsap">
          <h1 className="about-content--top_title title-1">Qui sommes-nous ?</h1>
          <h2 className="about-content--top_subtitle title-2 xlight">
            Notre mission: Remettre l’humain au coeur de la stratégie des entreprises
          </h2>
        </div>
        <div className="about-content--bottom gsap">
          {/* FONDATRICE */}
          <div className="about-content--bottom_fondatrice">
            <div className="about-content--bottom_fondatrice-img">
              {!imgLoaded && <SpinnerLoader />}
              <img src="/imgs/Ennayla_Arsafi_Formacoach.jpg" alt="formation" onLoad={() => setImgLoaded(true)} />
            </div>
            <div className="about-content--bottom_fondatrice-desc">
              <h3 className="title-3">La Fondatrice </h3>
              <p className="text-header thin">
                Depuis 2005, Ennayla Arsafi, a pour ambition d’accompagner les entreprises, les formateurs et les
                managers vers une réussite éthique et durable en déployant ses experts. Les outils innovants de
                Forma’Coach permettent d’appréhender la réalité d’un monde{' '}
                <abbr title="Volatile, Incertain, Complexe, Ambigü">VUCA</abbr>. Son expertise pointue acquise auprès de
                grandes entreprises lui offre de quoi nourrir sa logique systémique, elle mise sur la circularité des
                savoirs et de l’empathie, afin que CHACUN TROUVE humblement SA PLACE avec résilience. Certifiée
                Formatrice professionnelle pour adultes, Process Com®, Sociaux styles, Maître praticien PNL, Résilience
                en milieu professionnel (R@W®), Dynamics Conflict Model®.
              </p>
            </div>
          </div>
          {/* TEAM */}
          <div className="about-content--bottom_details gsap">
            <div className="about-content--bottom_details-team">
              <h3 className="about-content--bottom_details-team--title title-3">L'équipe</h3>
              <p className="about-content--bottom_details-team--desc text-main">
                Forma’Coach réunit autour de sa fondatrice une équipe de 20 coachs formateurs expérimentés, autant de
                talents pour accompagner les entreprises dans leurs actions de formation ou de changement.
              </p>
              <div className="about-content--bottom_details-team--img">
                {!imgLoaded && <SpinnerLoader />}
                <img
                  src={`/imgs/pages/${window.innerWidth > 600 ? 'large' : 'small'}/formacoach-equipe-${
                    window.innerWidth > 600 ? 'large' : 'small'
                  }.jpg`}
                  alt="formation"
                  onLoad={() => setImgLoaded(true)}
                />
              </div>
            </div>
            {/* AMBITIONS */}
            <div className="about-content--bottom_details-ambitions">
              <h3 className="about-content--bottom_details-ambitions-title title-3">Nos ambitions</h3>
              <p className="about-content--bottom_details-ambitions-desc text-main">
                Notre métier est d’accompagner la performance et le bien-être des collaborateurs, d’optimiser le
                maintien et la croissance de l’activité au niveau national et international. Forma’Coach s’intègre avec
                agilité à tous les environnements organisationnels et structurels, grâce à des formations softskills
                d’excellence et inclusives en direction des managers leaders. Notre dispositif pédagogique, des modules
                de formation « Blended » présentiels et/ou distanciels et du digital, permet de répondre aux processus
                cognitifs et renforcer les apprentissages. Le leader est l’organe central d’une entreprise, au
                croisement des difficultés de convergence entre la direction et les collaborateurs. La tenségrité d’une
                entreprise résulte de la répartition de la charge entre chaque membre de son système, c’est pourquoi, il
                est primordial de la répartir efficacement.
              </p>
            </div>
          </div>
        </div>
        <h2 className="about-content--values_title title-1">Nos valeurs : ÉTHIQUE</h2>
      </div>

      {/* VALUES */}
      {/* <ValuesComponent /> */}
      <div className="gsap">{window.innerWidth < 720 ? <ValuesMobileComponent /> : <ValuesComponent />}</div>
    </div>
  );
};

export default AboutPage;
