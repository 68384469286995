export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_SUBCATEGORIES = 'GET_SUBCATEGORIES';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_SUBCATEGORIES = 'SET_SUBCATEGORIES';

export const setSubcategories = (payload) => ({
  type: SET_SUBCATEGORIES,
  payload,
});

export const setCategories = (payload) => ({
  type: SET_CATEGORIES,
  payload,
});

export const getSubcategories = () => ({
  type: GET_SUBCATEGORIES,
});

export const getCategories = () => ({
  type: GET_CATEGORIES,
});
