/* eslint-disable max-len */
import { useRef, createRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import './styles.scss';

const ValuesComponent = () => {
  // GSAP INIT
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const scrollValuesTl = useRef();
  const colorItemTl = useRef();

  const ethiqueRef = createRef();
  const equiteRef = createRef();
  const tenseRef = createRef();
  const humanismRef = createRef();
  const intelRef = createRef();
  const qualRef = createRef();
  const unionRef = createRef();
  const excelRef = createRef();

  const scrollItem = (item, conf) => {
    const { reversed } = conf;
    // TIMELINE
    scrollValuesTl.current.fromTo(
      item,
      {
        x: `${reversed ? '-30%' : '30%'}`,
        // opacity: 0.5,
      },
      {
        x: `${window.innerWidth < 1400 ? '-14%' : '-5%'}`,
        duration: 10,
        // opacity: 1,
        ease: 'expo',
        scrollTrigger: {
          scrub: 2,
          trigger: item,
          toggleActions: 'play reverse play reverse',
          start: 'top bottom',
          end: 'top 75%',
        },
      },
      '<'
    );

    scrollValuesTl.current.play();

    return scrollValuesTl;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // Color timeline
      colorItemTl.current = gsap.timeline({ paused: true });

      // Scroll timeline
      scrollValuesTl.current = gsap.timeline({
        paused: true,
      });

      // Get every line and put it in an array
      gsap.utils.toArray('#gsap_line').forEach((line, i) => {
        const reversed = i % 2 === 0;

        scrollItem(line, { reversed });
      });
    }

    return () => {
      isMounted = false;
      scrollValuesTl.current.kill();
    };
  }, []);

  return (
    <div className="values">
      <ul className="values-list">
        {/* 1 */}
        <li className="values-list--item">
          <div id="gsap_line">
            <span className="values-list--item_line">
              formacoach humour{' '}
              <span className="values-list--item_line-value" ref={equiteRef}>
                <span className="filled">É</span>quité
              </span>{' '}
              union autonomie humanisme responsabilité
              <span className="spacer">i</span>
            </span>
          </div>
        </li>
        {/* 2 */}
        <li className="values-list--item">
          <div id="gsap_line">
            <span className="values-list--item_line">
              responsabilité Adaptabilité{' '}
              <span className="values-list--item_line-value" ref={tenseRef}>
                <span className="filled">T</span>enségrité
              </span>{' '}
              bienveillance parité pédagogie
              <span className="spacer">i</span>
            </span>
          </div>
        </li>
        {/* 3 */}
        <li className="values-list--item">
          <div id="gsap_line">
            <span className="values-list--item_line">
              {' '}
              gentillesse{' '}
              <span className="values-list--item_line-value" ref={excelRef}>
                <span className="filled">E</span>xcellence
              </span>{' '}
              partage{' '}
              <span className="values-list--item_line-value" ref={humanismRef}>
                <span className="filled">H</span>umanisme
              </span>{' '}
              écoute Cerveau <span className="spacer">i</span>
            </span>
          </div>
        </li>
        {/* 4 */}
        <li className="values-list--item">
          <div id="gsap_line">
            <span className="values-list--item_line">
              Honnêteté positivité{' '}
              <span className="values-list--item_line-value" ref={ethiqueRef}>
                <span className="filled">Éthique</span>
              </span>{' '}
              loyauté responsabilité neuro-science formacoach pédagogie <span className="spacer">i</span>
            </span>
          </div>
        </li>
        {/* 5 */}
        <li className="values-list--item">
          <div id="gsap_line">
            <span className="values-list--item_line">
              {' '}
              Confiance en soi performance{' '}
              <span className="values-list--item_line-value" ref={intelRef}>
                <span className="filled">I</span>ntelligence
              </span>{' '}
              écoute partage
              <span className="spacer">i</span>
            </span>
          </div>
        </li>
        {/* 6 */}
        <li className="values-list--item">
          <div id="gsap_line">
            <span className="values-list--item_line">
              organisation{' '}
              <span className="values-list--item_line-value" ref={unionRef}>
                <span className="filled">U</span>nion
              </span>{' '}
              Professionnalisme exemplarité apprentissage leadership
            </span>
          </div>
        </li>
        {/* 7 */}
        <li className="values-list--item">
          <div id="gsap_line">
            <span className="values-list--item_line">
              Bienveillance tenségrité{' '}
              <span className="values-list--item_line-value" ref={qualRef}>
                <span className="filled">Q</span>ualité
              </span>{' '}
              parité partage formation digital cerveau
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
};
export default ValuesComponent;

// {
//   /* 7 */
// }
// <li className="values-list--item">
//   <div className="values-list--item_strok">
//     Bienveillance tenségrité <span className="values-list--item_line-value" ><span className="filled">Q</span>ualité</span> parité partage formation digital cerveau
//   </div>
//   <div className="values-list--item_stroke-2">
//     Bienveillance tenségrité <span className="values-list--item_line-value" ><span className="filled">Q</span>ualité</span> parité partage formation
//     digital cerveau
//   </div>
// </li>;
