/* eslint-disable max-len */
import './styles.scss';

import { FaCookieBite } from 'react-icons/fa';

const MentionsLegalesPage = () => (
  <div className="legals page">
    <h2 className="legals-title title-1">Mentions légales</h2>
    <div className="legals-content">
      {/* Données */}
      <div className="legals-content--data text-main">
        <h3 className="legals-subtitle title-3">Cookies & Données personnelles</h3>
        <FaCookieBite className="legals-content--data_picto" style={{ fontSize: '3rem' }} />
        <p>Ce site n'utilise pas de cookies et ne collecte ni données ni informations sur votre navigation.</p>
      </div>

      {/* Propriété intellectuelle */}
      <div className="legals-content--intellectuelle text-main">
        <h3 className="legals-subtitle title-3">Propriété intellectuelle</h3>
        <p>
          {' '}
          Ce site internet est la propriété de FORMA'COACH. <br />
          <br />
          Les marques FORMA'COACH ou toutes autres marques figurant sur le présent site sont des marques déposées et
          protégées. Elles sont la propriété exclusive de leurs titulaires respectifs. <br />
          <br />
          Toute extraction, par transfert permanent ou temporaire, de la totalité ou d'une partie du contenu du présent
          site internet sur un autre support, par tout moyen ou sous toute forme que ce soit, ainsi que la
          réutilisation, par la mise à disposition du public de la totalité ou d'une partie du contenu du présent site
          internet, quelle qu'en soit la forme, est illicite. Tous les éléments (textes, commentaires, ouvrages,
          illustrations, logos, marques, vidéos et images, sans que cette liste soit limitative) affichés ou citées sur
          le présent site internet sont la propriété exclusive de leurs titulaires respectifs. Conformément au Code de
          la Propriété Intellectuelle, toute utilisation ou reproduction totale ou partielle desdits éléments effectuée
          à partir du présent site internet sans l'autorisation expresse et écrite de FORMA'COACH est interdite.
        </p>
      </div>

      {/* Utilisation du site */}
      <div className="legals-content--usage text-main">
        <h3 className="legals-subtitle title-3">Utilisation du site</h3>
        <p>
          {' '}
          FORMA'COACH s’engage à mettre en œuvre tous les moyens à sa disposition pour assurer la fiabilité de
          l’utilisation du présent site internet et de ses contenus. Néanmoins, FORMA'COACH ne donne aucune garantie et
          ne saurait en aucun cas être responsable de l’utilisation faite du présent site internet. Notamment,
          FORMA'COACH ne saurait être tenu responsable en cas d’interruptions, de saturation du réseau internet, de
          défaillance de tout matériel de réception ou des lignes de communication, ou de tout autre évènement impactant
          ou résultant de l’utilisation du présent site internet. <br />
          <br />
          De même, FORMA'COACH ne saurait voir sa responsabilité engagée en raison de la nature ou du contenu des pages
          constituant le présent site internet ou des sites tiers référencés sur les pages du présent site internet
          notamment ceux pour lesquels il existe un lien hypertexte.
        </p>
      </div>

      {/* Design & Hébergement */}
      <div className="legals-content--technical text-main">
        <h3 className="legals-subtitle title-3">Éditeur du site</h3>
        <p>
          Ce site est hébergé par IONOS SARL (7, place de la Gare, BP 70109, 57200 Sarreguemines Cedex, 431 303 775 RCS
          Sarreguemines, tél. : 0970 808 911, info@IONOS.fr)
        </p>
        <p>Directrice de la publication : Ennayla Arsafi</p>
        <p>
          Design & Développement : Antoine Garcia |{' '}
          <a className="legals-link" href="https://www.antoinegarcia.com" target={'_blank'} rel="noreferrer">
            www.antoinegarcia.com
          </a>
        </p>
      </div>

      {/* Copyright */}
      <div className="legals-content--copyright text-main">
        <h3 className="legals-subtitle title-3">Crédits photographiques</h3>
        Les images utilisées proviennent de{' '}
        <a className="legals-link" href="https://www.pexels.com/" target={'_blank'} rel="noreferrer">
          www.pexels.com
        </a>
      </div>
    </div>
  </div>
);

export default MentionsLegalesPage;
