/* eslint-disable no-underscore-dangle */
import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

// Handle Time
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/fr';

import { MdTimer, MdKeyboardArrowDown, MdAccessibleForward } from 'react-icons/md';

import { Link } from 'react-router-dom';
import NavButton from '../../Utils/UI/NavButton';
import DangerouslyDisplayHTMLComponent from '../../Utils/UI/DangerouslyDisplayHTMLComponent';

import './styles.scss';
import ImgComponent from '../../Utils/UI/ImgComponent';

dayjs.extend(relativeTime);
dayjs.locale('fr');

const FormationCardComponent = ({ event, index }) => {
  const [imgUrl, setImgUrl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isHover, setIsHover] = useState(false);

  const handleDisplayProgram = () => {
    setIsOpen(!isOpen);
  };

  const memoedEvent = useMemo(() => {
    setImgUrl(
      event?.categories?.length > 0
        ? `${event?.categories[0]?.catTitle}-${index}-600`
        : `${event?.category?.catTitle}-${index}-600`
    );
    // setImgUrl(`${event?.category?.catTitle}${index}`);
    return event;
  }, [event]);

  return (
    <div className="formation-card">
      <Link
        to={`/${memoedEvent?.duration !== undefined ? 'ateliers' : 'events'}/${memoedEvent?._id}/${index}`}
        className="formation-card--header"
      >
        <div className="formation-card--header_title">{memoedEvent?.title}</div>
      </Link>
      <div className="formation-card--img">
        <ImgComponent url={imgUrl} />
        {/* {!imgLoaded && <SpinnerLoader />}
        <img src={imgUrl} alt={`${memoedEvent?.category?.catTitle}`} onLoad={() => setImgLoaded(true)} /> */}
      </div>
      <div
        className="formation-card--program"
        onClick={handleDisplayProgram}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        style={{
          maxHeight: `${isOpen ? '100%' : '10rem'}`,
          overflow: 'hidden'
        }}
      >
        <p
          style={{
            fontSize: '1rem',
            fontWeight: 'bold'
          }}
        >
          Programme :
        </p>
        <DangerouslyDisplayHTMLComponent className="formation-card--program_desc" content={memoedEvent?.program} />
      </div>
      <div
        className="shadowbox"
        onClick={handleDisplayProgram}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <MdKeyboardArrowDown
          className="formation-card--arrow"
          style={{
            transform: `rotate(${isOpen ? '180deg' : '0deg'}) translateY(${isHover ? '0.5rem' : '0'})`,
            transition: '200ms ease-in-out'
          }}
        />
      </div>
      <div className="formation-card--details">
        <div className="formation-card--details_infos">
          <span
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <div className="formation-card--details_infos__duration">
              <MdTimer />
              {`${
                memoedEvent?.duration !== undefined
                  ? `${memoedEvent?.duration}`
                  : `${dayjs(memoedEvent?.dateEnd).add(1, 'day').to(memoedEvent?.dateStart, true)}`
              }`}
            </div>
            <div className="formation-card--details_infos__location">| {memoedEvent?.location}</div>
            {memoedEvent?.certification && (
              <div className="formation-card--details_infos__certification">| Certifiante</div>
            )}
            <abbr
              title="Pour les personnes en situation d'handicap des solutions et lieux spécifiques peuvent être envisagés sur demande. Nous contacter."
              style={{
                cursor: 'help',
                display: 'flex',
                fontWeight: 100,
                gap: '0.35rem',
                alignItems: 'center'
              }}
            >
              | <MdAccessibleForward />
            </abbr>
          </span>{' '}
        </div>
        <div className="formation-card--details_btn">
          <NavButton
            to={`/${memoedEvent?.duration !== undefined ? 'ateliers' : 'events'}/${memoedEvent?._id}/${index}`}
            text={'En savoir plus'}
          />
        </div>
      </div>
    </div>
  );
};

FormationCardComponent.propTypes = {
  index: PropTypes.number.isRequired,
  event: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    program: PropTypes.string.isRequired,
    category: PropTypes.shape({
      catTitle: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired
    }),
    subcategory: PropTypes.shape({
      subCatTitle: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired
    }),
    audience: PropTypes.string.isRequired,
    prerequisites: PropTypes.string.isRequired,
    objectives: PropTypes.string.isRequired,
    modalities: PropTypes.string.isRequired,
    dateStart: PropTypes.string,
    dateEnd: PropTypes.string,
    duration: PropTypes.string,
    location: PropTypes.string.isRequired,
    price: PropTypes.number,
    intra: PropTypes.bool.isRequired,
    inter: PropTypes.bool.isRequired,
    certification: PropTypes.bool.isRequired,
    display: PropTypes.bool.isRequired
  })
};

export default FormationCardComponent;
