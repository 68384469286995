import axios from 'axios';

import { SUBMIT_CONTACT_FORM, SUBMIT_INSCRIPTION_FORM } from '../actions/contacts';
import { startLoading, stopLoading } from '../actions/loading';
import { displaySuccess, displayError, apiHasReturned } from '../actions/apiResponses';

const contactsMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case SUBMIT_CONTACT_FORM: {
      // !!!!! DONT FORGET STORE.DISPATCH MODAFUKA !!!!!
      store.dispatch(startLoading());
      axios
        .post(`${process.env.REACT_APP_API_URL}/contacts/infos`, action.payload)
        .then((response) => {
          if (response.status === 200) store.dispatch(displaySuccess(response.status));
        })
        .catch((err) => {
          store.dispatch(displayError(err));
        })
        .finally(() => {
          store.dispatch(stopLoading());
          store.dispatch(apiHasReturned());
        });
      break;
    }
    case SUBMIT_INSCRIPTION_FORM: {
      // !!!!! DONT FORGET STORE.DISPATCH MODAFUKA !!!!!
      store.dispatch(startLoading());
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/contacts/inscription-${
            action.payload.dateStart && action.payload.dateEnd ? 'formation' : 'atelier'
          }`,
          action.payload
        )
        .then((response) => {
          // console.log(response);
          if (response.status === 200) store.dispatch(displaySuccess(response.status));
        })
        .catch((err) => {
          store.dispatch(displayError(err));
        })
        .finally(() => {
          store.dispatch(stopLoading());
          store.dispatch(apiHasReturned());
        });
      break;
    }
    default:
      next(action);
  }
};

export default contactsMiddleware;
