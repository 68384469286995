/* eslint-disable max-len */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import { gsapAnimate } from '../../services/gsap';

import logo from './logo-small.png';
import logoXs from './logo-mono-small.png';

import MenuComponent from '../../containers/menuContainer';

import './styles.scss';

const Header = ({ setPreviousLocation }) => {
  const location = useLocation();

  const value1 = `M ${window.innerWidth < 1400 ? '1400' : window.innerWidth} 111 C 1116 52 577 130 0 90 V 0 l ${
    window.innerWidth
  } 0 V 111 Z`;

  useEffect(() => {
    gsapAnimate('.header', {
      opacity: 1,
    });
  }, []);

  return (
    <div className="header">
      <div className="header-content">
        <div className="header-content--logo">
          <Link to="/" onClick={() => setPreviousLocation(location)}>
            <img className="header-content--logo_img" src={window.innerWidth < 320 ? logoXs : logo} alt="formacoach" />
          </Link>
        </div>
        <div className="header-content--menu">
          <MenuComponent />
        </div>
      </div>
      <svg className="header-content--wave" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
        {/* <path fill="#FFFFFF" className="header-wave--path" d={`${value1}`} /> */}
        <defs>
          <clipPath id="header-clip">
            <path fill="#FFFFFF" className="header-content--wave_path" d={`${value1}`} />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

Header.propTypes = {
  setPreviousLocation: PropTypes.func.isRequired,
};

export default Header;
