/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import SpinnerLoader from '../SpinnerLoader';

import './styles.scss';

const ImgComponent = ({ url }) => {
  const [imgLoaded, setImgLoaded] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setImgUrl(url);
    }

    return () => {
      isMounted = false;
    };
  });

  return (
    <div className="imgComponent">
      {!imgLoaded && <SpinnerLoader />}
      {imgUrl !== null && (
        <img
          // Don't know why but src={`/imgs/categories/${url}.jpg`} won't load anywhere event if it exists
          // Requiering the image seems to fix the problem

          // src={require(`../../../../assets/imgs/categories/${url}.jpg`)}
          src={require(`../../../../../public/imgs/categories/${imgUrl || url || 'formateur-1-600'}.jpg`)}
          alt={`${imgUrl} image`}
          onLoad={() => setImgLoaded(true)}
          // If it fails, we try to load public images
          onError={(e) => {
            e.target.src = `/imgs/categories/${url}`;
          }}
        />
      )}
    </div>
  );
};

ImgComponent.propTypes = {
  url: PropTypes.string,
};

export default ImgComponent;
