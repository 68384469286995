import { connect } from 'react-redux';
import OfferComponent from '../components/HomePageComponent/OfferComponent';

const mapStateToProps = (state) => ({
  isLoading: state.loading.isLoading,
  eventsList: state.events.eventsList,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OfferComponent);
