import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

import './styles.scss';

// prettier-ignore
const menuComponent = ({
  isMenuOpen,
  openMenu,
  closeMenu,
  setPreviousLocation,
}) => {
  const location = useLocation();

  const handleOpenMenu = () => {
    if (isMenuOpen) {
      closeMenu();
    }
    // prettier-ignore
    else {
      openMenu();
    }
  };

  // Close menu when location changes
  useEffect(() => {
    closeMenu();
  }, [location]);

  return (
    <nav className={isMenuOpen ? 'nav nav-open' : 'nav nav-closed'}>
      <button className="nav-btn" onClick={handleOpenMenu}>
        <span className={isMenuOpen ? 'nav-btn--close' : 'nav-btn--open'} />
        <span className="sr-only">menu</span>
      </button>
      <ul className="nav-list">
        {/* About */}
        <li>
          <NavLink
            active="active"
            className="nav-list--item"
            to={'/about'}
            onClick={() => setPreviousLocation(location)}
          >
            {/* <p className="title-4"> */}
            Qui sommes-nous ?
            {/* </p> */}
          </NavLink>
        </li>
        {/* Formations */}
        <li>
          <NavLink
            active="active"
            className="nav-list--item"
            to={'/entreprises'}
            onClick={() => setPreviousLocation(location)}
          >
            Entreprises
          </NavLink>
        </li>
        {/* Coaching */}
        <li>
          <NavLink
            active="active"
            className="nav-list--item"
            to={'/coachs'}
            onClick={() => setPreviousLocation(location)}
          >
            Coachs
          </NavLink>
        </li>
        {/* Ateliers */}
        <li>
          <NavLink
            active="active"
            className="nav-list--item"
            to={'/formateurs'}
            onClick={() => setPreviousLocation(location)}
          >
            Formateurs
          </NavLink>
        </li>
        {/* Sourcing formateurs */}
        <li>
          <NavLink
            active="active"
            className="nav-list--item"
            to={'/sourcing'}
            onClick={() => setPreviousLocation(location)}
          >
            {/* <p className="title-4"> */}
            Sourcing de formateurs

            {/* </p> */}
          </NavLink>
        </li>
        <li>
          <NavLink
            active="active"
            className="nav-list--item"
            to={'/contact'}
            onClick={() => setPreviousLocation(location)}
          >
            {/* <p className="title-4"> */}
            Contact

            {/* </p> */}
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

menuComponent.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  openMenu: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default menuComponent;
