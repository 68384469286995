import { combineReducers } from 'redux';

import loadingReducer from './loading';
import menuReducer from './menu';
import navigationReducer from './navigation';
import eventsReducer from './events';
import apiReducer from './api';
import categoriesReducer from './categories';

const rootReducer = combineReducers({
  loading: loadingReducer,
  menu: menuReducer,
  navigation: navigationReducer,
  events: eventsReducer,
  api: apiReducer,
  categories: categoriesReducer,
});

export default rootReducer;
