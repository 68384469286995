import { connect } from 'react-redux';
import ContactPage from '../pages/ContactsPage';

import { submitContactForm } from '../actions/contacts';
import { resetResponse } from '../actions/apiResponses';

const mapStateToProps = (state) => ({
  isLoading: state.loading.isLoading,
  apiResponse: state.api.apiResponse,
  apiError: state.api.apiError,
  apiHasReturned: state.api.apiHasReturned,
});

const mapDispatchToProps = (dispatch) => ({
  submitContactForm: (payload) => dispatch(submitContactForm(payload)),
  resetResponse: () => dispatch(resetResponse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
