/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { MdTimer } from 'react-icons/md';

import '../styles.scss';

const AteliersListItem = ({ atelier, index }) => (
  <Link to={`/ateliers/${atelier._id}/${index > 2 ? 2 : index}`}>
    <div className="ateliers-list--item">
      <span className="ateliers-list--item_title">{atelier.title}</span>
      <div className="ateliers-list--item_details">
        <MdTimer />
        <span className="ateliers-list--item_details-duration">{atelier.duration}</span>
        {atelier.certification && <span className="ateliers-list--item_details-certification"> | Certification</span>}
        <div className="ateliers-list--item_details-atelier">| Atelier </div>
      </div>
    </div>
  </Link>
);

AteliersListItem.propTypes = {
  index: PropTypes.number.isRequired,
  atelier: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    program: PropTypes.string.isRequired,
    category: PropTypes.shape({
      catTitle: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
    subcategory: PropTypes.shape({
      subCatTitle: PropTypes.string.isRequired,
      _id: PropTypes.string.isRequired,
    }),
    audience: PropTypes.string.isRequired,
    prerequisites: PropTypes.string.isRequired,
    objectives: PropTypes.string.isRequired,
    modalities: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    intra: PropTypes.bool.isRequired,
    inter: PropTypes.bool.isRequired,
    certification: PropTypes.bool.isRequired,
    display: PropTypes.bool.isRequired,
  }),
};

export default AteliersListItem;
