// prettier-ignore
import {
  DISPLAY_SUCCESS,
  DISPLAY_ERROR,
  API_HAS_RETURNED,
  RESET_RESPONSE
} from '../actions/apiResponses';

export const initialState = {
  apiHasReturned: false,
  apiResponse: null,
  apiError: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case DISPLAY_SUCCESS:
      return {
        ...state,
        apiResponse: action.payload,
      };
    case DISPLAY_ERROR:
      return {
        ...state,
        apiError: action.payload,
      };
    case API_HAS_RETURNED:
      return {
        ...state,
        apiHasReturned: true,
      };
    case RESET_RESPONSE:
      return {
        initialState,
      };
    default:
      return state;
  }
};

export default reducer;
