import { START_LOADING, STOP_LOADING, START_FIRST_LOADING } from '../actions/loading';

export const initialState = {
  loadingCpt: 0,
  isLoading: false,
  isFirstLoading: true,
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case START_FIRST_LOADING:
      return {
        ...state,
        isFirstLoading: false,
        loadingCpt: state.loadingCpt + 1,
      };
    case STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
