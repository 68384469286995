import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';

import store from './store';

import './styles/index.scss';

import Formacoach from './containers/formacoachContainer';

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router basename="/">
        <Formacoach />
      </Router>
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
