import { connect } from 'react-redux';
import LoadingWave from '../../components/Utils/UI/FirstLoading';

import { startFirstLoading } from '../../actions/loading';

const mapStateToProps = (state) => ({
  isFirstLoading: state.loading.isFirstLoading,
});

const mapDispatchToProps = (dispatch) => ({
  startFirstLoading: () => dispatch(startFirstLoading()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoadingWave);
