import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './styles.scss';

const NavButton = ({ to, text }) => (
  <Link className="navButton" to={to}>
    {text}
  </Link>
);

NavButton.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

export default NavButton;
