export const DISPLAY_SUCCESS = 'DISPLAY_SUCCESS';
export const DISPLAY_ERROR = 'DISPLAY_ERROR';
export const API_HAS_RETURNED = 'API_HAS_RETURNED';
export const RESET_RESPONSE = 'RESET_RESPONSE';

export const resetResponse = () => ({
  type: RESET_RESPONSE,
});

export const apiHasReturned = () => ({
  type: API_HAS_RETURNED,
});

export const displaySuccess = (payload) => ({
  type: DISPLAY_SUCCESS,
  payload,
});

export const displayError = (payload) => ({
  type: DISPLAY_ERROR,
  payload,
});
