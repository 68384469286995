import { useState } from 'react';
import PropTypes from 'prop-types';

import SpinnerLoader from '../../../Utils/UI/SpinnerLoader';
import './styles.scss';

const OfferCardComponent = ({ offer, hasBorder = false }) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  return (
    <>
      {hasBorder ? (
        <div className={`offerCard ${hasBorder && 'border'}`}>
          <div className="offerCard-picto">
            {!imgLoaded && <SpinnerLoader />}
            <img
              src={`/icons/${offer.slug}-icon.svg`}
              alt={`${offer.title} pictogram`}
              onLoad={() => setImgLoaded(true)}
            />
          </div>
          <h2 className="offerCard-title">{offer.title}</h2>
        </div>
      ) : (
        <div className={`offerCard ${hasBorder && 'border'}`}>
          <div className="offerCard-picto">
            {!imgLoaded && <SpinnerLoader />}
            <img
              src={`/icons/${offer.slug}-icon.svg`}
              alt={`${offer.title} pictogram`}
              onLoad={() => setImgLoaded(true)}
            />
          </div>
          <h2 className="offerCard-title">{offer.title}</h2>
        </div>
      )}
    </>
  );
};

OfferCardComponent.propTypes = {
  hasBorder: PropTypes.bool,

  offer: PropTypes.shape({
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }),
};

OfferCardComponent.defaultProps = {
  hasBorder: false,
};

export default OfferCardComponent;
