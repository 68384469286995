/* eslint-disable operator-linebreak */
/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { MdTimer } from 'react-icons/md';
import './styles.scss';

const AteliersList = ({ ateliers }) => (
  <div className="ateliers">
    <h3 className="ateliers-title title-3 bold italic">Tous nos ateliers Entreprise :</h3>
    <div className="ateliers-list">
      {ateliers?.length > 0 &&
        ateliers?.map((atelier, index) => {
          // eslint-disable-next-line no-param-reassign
          index += 1;
          return (
            <Link key={atelier._id} to={`/ateliers/${atelier?._id}/${index}`} className="formation-listItem">
              <span className="formation-listItem--title">{atelier?.title}</span>
              <div className="formation-listItem--details">
                <MdTimer />
                <span className="formation-listItem--details_duration">2 heures | À distance</span>
              </div>
            </Link>
          );
        })}
    </div>
  </div>
);

AteliersList.propTypes = {
  ateliers: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      duration: PropTypes.string,
      location: PropTypes.string.isRequired,
      price: PropTypes.number,
      display: PropTypes.bool.isRequired,
    })
  ),
};

export default AteliersList;
