/* eslint-disable max-len */
import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import HomePresentation from '../../components/HomePageComponent/PresentationComponent';
import HomeChart from '../../components/HomePageComponent/ChartComponent';
import ClientsLogosComponent from '../../components/HomePageComponent/ClientsLogosComponent';

// Containers
import HomeOffer from '../../containers/offerComponentContainer';

import './styles.scss';

const HomePage = () => {
  // GSAP INIT
  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const scrollTl = useRef();

  const scrollItem = (item) => {
    // TIMELINE
    scrollTl.current.fromTo(
      item,
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        duration: 10,
        opacity: 1,
        ease: 'expo',
        scrollTrigger: {
          scrub: 2,
          trigger: item,
          toggleActions: 'play reverse play reverse',
          start: 'top 90%',
          end: 'top 75%',
        },
      },
      '+=1'
    );

    scrollTl.current.play();

    return scrollTl;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // Scroll timeline
      scrollTl.current = gsap.timeline({
        paused: true,
      });

      // Get every line and put it in an array
      gsap.utils.toArray('.gsap').forEach((line) => {
        scrollItem(line);
      });
    }

    return () => {
      isMounted = false;
      scrollTl.current.kill();
    };
  }, []);

  return (
    <div className="homepage">
      <section className="homepage-section">
        <HomePresentation />
      </section>
      <section className="homepage-section gsap">
        <HomeOffer />
      </section>
      <section className="homepage-section gsap">
        <HomeChart />
      </section>
      <section className="homepage-section gsap">
        <ClientsLogosComponent />
      </section>
    </div>
  );
};

export default HomePage;
