/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-underscore-dangle */
// prettier-ignore
import {
  useState,
  useEffect,
  useRef,
  useMemo
} from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import PropTypes from 'prop-types';

import SpinnerLoader from '../../components/Utils/UI/SpinnerLoader';
import NavButton from '../../components/Utils/UI/NavButton';

import PagesFormationsComponent from '../../components/PagesFormationsComponent';
import CoachingElements from './CoachingElements';

import './styles.scss';
import AteliersList from './AteliersList';

// prettier-ignore
const EntreprisePage = ({
  eventsList,
  isLoading,
  categories,
}) => {
  // const [isSelected, setIsSelected] = useState(false);
  const [category, setCategory] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);

  const ateliersList = useMemo(
    () => eventsList?.filter((e) => e.subcategory),
    [eventsList]
  );

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  const scrollTl = useRef();

  const scrollItem = (item) => {
    // TIMELINE
    scrollTl.current.fromTo(
      item,
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        duration: 10,
        opacity: 1,
        ease: 'expo',
        scrollTrigger: {
          scrub: 2,
          trigger: item,
          toggleActions: 'play reverse play reverse',
          start: 'top 90%',
          end: 'top 75%',
        },
      },
      '+=1'
    );

    scrollTl.current.play();

    return scrollTl;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      // Scroll timeline
      scrollTl.current = gsap.timeline({
        paused: true,
      });

      // Get every line and put it in an array
      gsap.utils.toArray('.gsap').forEach((line) => {
        scrollItem(line);
      });
    }

    return () => {
      isMounted = false;
      scrollTl.current.kill();
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setCategory(categories?.find((cat) => cat.catTitle === 'entreprise'));
    }

    return () => {
      isMounted = false;
    };
  }, [categories]);

  const handleFilterEvents = (subcat) => {
    if (subcat._id === selectedCategoryId && selectedCategoryId !== null) {
      setSelectedCategoryId(null);
      setSelectedCategoryName(null);
      // setIsSelected(false);
    }
    else {
      setSelectedCategoryId(subcat._id);
      setSelectedCategoryName(subcat.subCatTitle);
      // setIsSelected(true);
    }
  };

  return (
    <div className="entreprise page">
      {/* CATEGORIES LIST */}
      <section className="gsap">
        <h2 className="title-1">Nos offres Entreprises</h2>
        <div className="entreprise-categories">
          <h3 className="entreprise-categories--title title-4 italic">Catégories :</h3>
          {/* Map categories */}
          <div className="entreprise-categories--list">
            {category?.subcategories?.length > 0
              && category?.subcategories?.map((subcat) => (
                <div
                  key={subcat._id}
                  onClick={() => handleFilterEvents(subcat)}
                  className={'entreprise-categories--list_item'}
                  style={{
                    background: `${subcat._id === selectedCategoryId ? '#951b81' : '#FFFFFF50'}`,
                  }}
                >
                  {subcat.subCatTitle}
                </div>
              ))}
          </div>
        </div>
      </section>
      <section className="gsap">
        {isLoading ? (
          <SpinnerLoader />
        ) : (
          <>
            {selectedCategoryName === 'coaching' ? (
              <CoachingElements />
            ) : (
              <>
                <PagesFormationsComponent
                  page={'entreprise'}
                  eventsList={eventsList}
                  selectedCategoryId={selectedCategoryId}
                  selectedCategoryName={selectedCategoryName}
                />
                {!selectedCategoryName && <AteliersList ateliers={ateliersList} />}
              </>
            )}
          </>
        )}
        <div className="button">
          <NavButton to={'/contact'} text={'En savoir plus'} />
        </div>
      </section>
    </div>
  );
};

EntreprisePage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      catTitle: PropTypes.string.isRequired,
      subcategories: PropTypes.array,
    })
  ),
  subcategories: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      subCatTitle: PropTypes.string.isRequired,
      categories: PropTypes.array,
    })
  ),
  eventsList: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      program: PropTypes.string.isRequired,
      category: PropTypes.shape({
        catTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      subcategory: PropTypes.shape({
        subCatTitle: PropTypes.string.isRequired,
        _id: PropTypes.string.isRequired,
      }),
      audience: PropTypes.string.isRequired,
      prerequisites: PropTypes.string.isRequired,
      objectives: PropTypes.string.isRequired,
      modalities: PropTypes.string.isRequired,
      dateStart: PropTypes.string,
      dateEnd: PropTypes.string,
      location: PropTypes.string.isRequired,
      price: PropTypes.number,
      intra: PropTypes.bool.isRequired,
      inter: PropTypes.bool.isRequired,
      certification: PropTypes.bool.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ),
};

export default EntreprisePage;
